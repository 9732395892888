import { UserDetails } from '../user/userInfo';
import { EventInfo, DB_Error } from './eventModel';
import { AuthUser } from '../user/userAuthorization';

export const ToggleRSVPtoEvent = async (event: number, user: AuthUser): Promise<boolean> => {
    if(user.authToken === undefined) {
        return false
    }
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${user.authToken}` }
      }
      
    const res = await fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/events/${event}/rsvp`, requestOptions)
    const result = await res.json();
    if (result.result === 'success') {
        return true;
    }
    else {
        return false;
    }
}