interface userQuery
{
    result: string
}

interface eventID
{
    event: number,
    name: string
}
  
interface eventQuery
{
    data: eventID[]
}

export enum EventSessionCreationPermissionStatus
{
	notAUser=0,
	notAttendingEvent=1,
	noSessionPermissions=2,
	canCreateSessions=3
}

export const getUserSessionCreationPermissionsForEvent = async (userId: number, eventId: number): Promise<EventSessionCreationPermissionStatus> => 
{
    const requestOptions = 
    {
    method: 'GET',
    headers: { "Access-Control-Allow-Origin": "*", 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
    }

    // Check if user exists
    const userQuery: userQuery = await fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/user/' + userId, requestOptions)
    .then(response => response.json());
    if (userQuery.result === 'failure') { return EventSessionCreationPermissionStatus.notAUser; }

    // Check if user is attendinging event
    const eventQuery: eventQuery = await fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/eventUsers/user/' + userId, requestOptions)
    .then(response => response.json())
    console.log(eventQuery.data);
    if (eventQuery.data.filter((evt) => evt.event === eventId).length === 0) { return EventSessionCreationPermissionStatus.notAttendingEvent; }

    // Check if user has event editing permissions (for now, always)
    // TODO: Add name to role search
    if (false) { return EventSessionCreationPermissionStatus.noSessionPermissions; }
    return EventSessionCreationPermissionStatus.canCreateSessions;
}