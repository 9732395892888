
import React from 'react';
import FeatureInfo from '../../content/FeatureInfo';
import { getRSVPsToEvent, eventRsvpList } from '../calendar/types/eventModel';
import CollapsableVenue from '../../content/CollapsableVenue';
import CollapsableAdmin from '../../content/CollapsableAdmin';
import MarkdownCollapsable from '../../content/MarkdownCollapsable';
import RegistrationCollapsable from '../../content/RegistrationCollapsable';
import CollapsibleSchedule, { CalSessionC } from '../../content/CollapsibleSchedule';
import { useNavigate } from "react-router-dom";
import {getSpecificEvent, EventInfo } from "../../../models/event/eventModel";
import { getUserDetails, getUserDetailsByName, UserDetails } from "../../../models/user/userInfo";
import { getVenueDetails, VenueDetails } from '../venues/venueModels';
import { useParams } from 'react-router-dom';
import './EventPage.css';
import { toggleUserSessionStatus } from '../../../functions/Sessions';
import { getGsessionsForEvent } from '../../../functions/Sessions';
import CollapsableContent from '../../content/CollapsableContent';

interface eventPageProps
{
    loggedInUser ?: number,
    authToken ?: string
}


const EventPage = (props: eventPageProps) => 
{

    const [event, setEvent] = React.useState<EventInfo | undefined>(undefined);
    const [owner, setOwner] = React.useState<string| undefined>(undefined);
    const [user, setUser] = React.useState<UserDetails | undefined>(undefined);
    const [venue, setVenue] = React.useState<VenueDetails | undefined>(undefined);
    const [registerList, setRegisterList] = React.useState<eventRsvpList | undefined>(undefined);
    const [seshCList, setSeshCList] = React.useState<CalSessionC[] | undefined>(undefined);
    const [refresh, setRefresh] = React.useState<Boolean>(false);

    const navigate = useNavigate();

    const params = useParams();

    const eventId: number | undefined = params.eventId ? parseInt(params.eventId) : undefined;


    React.useEffect(() => 
    {
        //TODO: Make this trigger a single refresh in a less hacky way.
        if(refresh) 
        {
            setRefresh(false);
            return;
        }

        if(eventId === undefined) 
        {
            setEvent(undefined);
            setOwner(undefined);
            setVenue(undefined);
        }
        else 
        {
            getSpecificEvent(eventId).then((res) => 
            {

                res && setEvent(res);
                res && setOwner(res.owner);

                res && getVenueDetails(res.venue).then((ven) => 
                {
                    setVenue(ven);
                });

            });

            getGsessionsForEvent(eventId).then((res) => 
            {
                setSeshCList(res);
            });
        }
    }, [eventId,refresh]);


    React.useEffect(() =>
    {
        if(props.loggedInUser === undefined) 
        {
            setUser(undefined);

        }
        else 
        {
            getUserDetails(props.loggedInUser).then((res) => 
            {
                res && setUser(res);
            });
        }
    },[props.loggedInUser]);


    React.useEffect(() => 
    {
        if(eventId === undefined) 
        {
            setRegisterList(undefined);

        }
        else 
        {
            getRSVPsToEvent(eventId).then((rsvp) => 
            {
                rsvp && setRegisterList(rsvp);
            });
        }
    },[eventId]);


    if (!event || !owner || !venue || !registerList || !seshCList)
    {
        return (<div><b>Loading Event...</b></div>)
    }


    if (event.name === "Titan Buster")
    {

        const registeredBlob = <h4>You're Registered for Titan Buster!</h4>

        const unregisteredBlob = <h4>This is an Invite-Only Event.</h4>

        const eventType = 
        {
            id: 2,
            name: "Premium"
        };

        const registeredOnClick = () => 
        {
            navigate("/TitanBuster/temp_schedule")
        }

        const unregisteredOnClick = () => 
        {
            navigate("/Titanbuster/validation")
        }

        const registeredSection = <div>{registeredBlob}<button onClick={() => registeredOnClick()}>View Event Calendar</button></div>

        const unregisteredSection = <div>{unregisteredBlob}<button onClick={() => unregisteredOnClick()}>Validate Registration</button></div>

        return(
            <div>
                <img className="titan-logo" src="https://www.tabletopgamersalliance.com/i/TitanBuster/2024/TitanBuster_splash.png" alt="" />

                <FeatureInfo startTime={event.startTime} endTime={event.endTime} name={event.name} owner={owner}/>

                <div className="event-grid">

                    <div className="event-main">
                        <RegistrationCollapsable startingIsCollapsed={false} registrantList={registerList.data} eventInfo={event} eventType={eventType} user={user} authToken={props.authToken} registeredContent={registeredSection} unregisteredContent={unregisteredSection} collapsableTitle={"Registration"} registrationsName={"Registrations"} registrationsVerb={"Register"}/>
                        <MarkdownCollapsable title={"Details"} startingIsCollapsed={false} content={event.info} />
                    </div>

                    <div className="event-side">
                        <CollapsableVenue user={user} registrantList={registerList.data} venue={venue} isHidden={true} title={"Venue Info"} startingIsCollapsed={false} />
                    </div>

                </div>
            </div>
        );

    }
    else if (event.name === "Gamacea 2024") 
    {
        navigate("/2024/gamacea");
        return(<div><b>Loading Event...</b></div>);

    }
    else if (event.name === "Gamacea 2023") 
    {
        navigate("/2023/gamacea");
        return(<div><b>Loading Event...</b></div>);

    }
    else
    {

        const eventType = 
        {
            id: 0,
            name: "Standard"
        };

        const onRsvpToggle = (() =>
        {
            eventId && getRSVPsToEvent(eventId).then((rsvp) =>
            {
                rsvp && setRegisterList(rsvp);
            });
        });

        const onFullScheduleClick = () => 
        {
            navigate('/events/eventpage/calendar/' + eventId);
        }

        const onSignUpChange = (sesh: number, signUpStatus: boolean) => 
        {
            return toggleUserSessionStatus({user: props.loggedInUser, authToken: props.authToken}, sesh ,signUpStatus).then((result) => 
            {
                result && setRefresh(true);
                return result;
            });
        }

        const canUserEdit = user?.user_name === event.owner;

        return(
            <div>
                <FeatureInfo startTime={event.startTime} endTime={event.endTime} name={event.name} owner={owner} timeZone={'America/New_York'}/>

                <div className="event-grid">

                    <div className="event-main">
                        <RegistrationCollapsable startingIsCollapsed={false} registrantList={registerList.data} eventInfo={event} eventType={eventType} user={user} authToken={props.authToken} onRsvpToggle={onRsvpToggle} />
                        <MarkdownCollapsable title={"Details"} startingIsCollapsed={false} content={event.info} />
                        <CollapsibleSchedule onSignUpChange={onSignUpChange} authToken={props.authToken} evnt={event} sessionCList={seshCList} startingIsCollapsed={false} user={user} registrantList={registerList.data} onFullSchedule={onFullScheduleClick}/>
                        {event.mainMapImage && <CollapsableContent title={"Event Map"} startingIsCollapsed={true}>
                        <img src={"https://tabletopgamersalliance.com/i/ev/" +  event.id + "/" + event.mainMapImage} />
                        </CollapsableContent>}
                    </div>

                    <div className="event-side">
                        <CollapsableVenue user={user} registrantList={registerList.data} venue={venue} isHidden={false} title={"Venue Info"} startingIsCollapsed={false} />
                        {canUserEdit && props.authToken && <CollapsableAdmin title={'Event Host Panel'} startingIsCollapsed={true} loggedInUser={user.id} authToken={props.authToken} event={{...event, owner:user.id}} />}
                    </div>

                </div>
            </div>
        );
    }
    

}

export default EventPage;