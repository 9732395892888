import VenueDetailedView from './VenueDetailedView';
import React from 'react';
import { useParams } from 'react-router-dom';
import { VenueDetails, getVenueDetails } from './venueModels';


interface SingleVenueViewProps {
    loggedInUser?: number,
    authToken?: string
}

const SingleVenueView = (props: SingleVenueViewProps) => {

    const [venueBlock, setVenueBlock] = React.useState<VenueDetails | undefined>()
    const [fetchErr, setFetchErr] = React.useState<boolean>(false);

    const params = useParams();

    const venueId: number | undefined = params.venueId ? parseInt(params.venueId) : undefined;

    React.useEffect(() => {
        venueId && getVenueDetails(venueId, props.authToken, props.loggedInUser)
            .then((data) => {
                if (data) {
                    setVenueBlock(data);
                } else {
                    setVenueBlock(undefined);
                    setFetchErr(true);
                }
            })
    }, [venueId, props.loggedInUser, props.authToken])

    if (fetchErr) return (
        <b>A networking error has occured. Please try again.</b>
    )

    if (!params.venueId) return (
        <div><b>No Venue Id Provided.</b></div>
    )

    if (!venueBlock) return (
        <div><b>Loading Page...</b></div>
    )

    return (
        <div>
            <b>SingleVenueView is Under Construction.</b>
        </div>
    )

    if (!venueBlock) {
        return (<b>No Venue Found.</b>)
    }
}

export default SingleVenueView;