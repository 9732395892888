import React from 'react';

const eventPolicy = (props: any) =>
(
  <aside className="GAPolicy">
    <h3>Gamers Alliance In-Person Events Policy</h3>
    <main>
      <h4>The Right to Game in a Safe and Respectful Space</h4>
      <p>
        <ol>
          <li className="list-bullet">
            Gamers Alliance (AKA TGA) operates all of our events on the premise
            that all gamers have the inalienable and equal right to game in a
            safe and respectful space.
            <ol>
              <li className="list-bullet">
                When a gamer plays a game that they do not own, they are
                expected to treat that game better than they would treat the
                game if it were their own copy.
              </li>
              <li className="list-bullet">
                As gamers, we come a multitude of backgrounds, beliefs and
                cultures and we attend these gaming events to have fun and enjoy
                the company of our fellow gamers.
                <ol>
                  <li className="list-bullet">
                    Gamers should treat all of their fellow gamers with utmost
                    respect; regardless of whether they are gaming together.
                  </li>
                  <li className="list-bullet">
                    Gamers should neither berate, belittle, bully, nor
                    intentionally harm any of their fellow gamers.
                    <ol>
                      <li className="list-bullet">
                        TGA reserves the right the revoke the admission of any
                        gamers that carry out such negative/harmful
                        behaviors/actions.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li className="list-bullet">
                All gamers are expected to adhere to all of the policies,
                regulations, and laws for all of the legal jurisdictions that
                event's venue is located within including the policies of the
                venue and the Gamers Alliance In-Person Events Policy, itself.
                <ol>
                  <li className="list-bullet">
                    TGA reserves the right the revoke the admission of any
                    gamers that infringe upon any of the policies, regulations,
                    and laws noted above in addition to pressing charges when
                    applicable.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </p>

      <h4>Public Health Considerations for You and Your Fellow Gamers</h4>
      <p>
        <ol>
          <li className="list-bullet">
            Attendees should not attend if they are knowingly host to any
            contagious/infectious diseases, including but not limited to
            CoViD-19. We reserve the right to revoke the admission of anyone
            that may be considered a health risk to others.
          </li>
          <li className="list-bullet">
            Events may have designated mask-required space.
            <ol>
              <li className="list-bullet">
                A given event's initial mask-required space will be based on
                demand that has been communicated to TGA prior to the start of
                the event.
                <ol>
                  <li className="list-bullet">
                    Attendees that prefer mask-required gaming are strongly
                    encouraged to communicate this preference to TGA over the
                    event's Discord server, Meetup event page, and Facebook event
                    page.
                  </li>
                  <li className="list-bullet">
                    The designation of mask-required event space will be posted
                    to all event registrants on record via direct communication
                    (such as e-mail) and to all potential attendees via posts
                    about the event on TGA's social media outlets.
                  </li>
                </ol>
              </li>
              <li className="list-bullet">
                Attendees must wear masks at all times while present in the
                areas that are designated as mask-required.
              </li>
              <li className="list-bullet">
                Attendees intending to spend the entirety of their time in
                mask-required areas should make liberal use of
                Discord/Meetup/Facebook messaging as well as scheduled games.
                Because the main congregation areas will be mask-optional
                spaces, attempting to find a game while remaining in the
                mask-required area may prove difficult.
              </li>
              <li className="list-bullet">
                TGA reserves the right to re-designate mask-required space
                during the event by increasing or decreasing the size of
                the mask-required space based on demand over the course of
                the event.
              </li>
            </ol>
          </li>
          <li className="list-bullet">
            Attendees [_they_] may also be asked to wear masks (or may be asked
            to remove themselves from the vicinity of the requester) if a
            masked gamer requests it during a game _they_ have sat down to play
            with the requester or when _they_ approach a group or individual that
            is masked.
          </li>
          <li className="list-bullet">
            TGA reserves the right to enact more safety protocols for the event
            in accordance with guidelines from government institutions OR above
            and beyond those guidelines to operate the event with an abundance
            of caution.
            <ol>
              <li className="list-bullet">
                Should this occur, TGA will communicate such enactments as soon
                as possible.
              </li>
              <li className="list-bullet">
                Refunds due to changes in this policy will not be given. Gamers
                concerned about this policy are encouraged to register at the
                door.
              </li>
            </ol>
          </li>
        </ol>
      </p>
    </main>
  </aside>
);

export default eventPolicy;
