import "react-big-calendar/lib/css/react-big-calendar.css";
import React from 'react';
//import Select from 'react-select';
//import VenueDetailedView from './VenueDetailedView';
//import VenueUpdater from './VenueUpdater';
import { VenueDropdownListItem, VenueDetails, getAllVenues, getVenueDetails } from './venueModels';

interface VenueViewerProps {
    loggedInUser?: number,
    authToken?: string
}

const VenueViewer = (props: VenueViewerProps) => {
    const [venueList, setVenueList] = React.useState<VenueDropdownListItem[]>([]);
    const [venueHasProblems, setVenueHasProblems] = React.useState<boolean>(false);
    const [fullSelectedVenue, setFullSelectedVenue] = React.useState<VenueDetails | undefined>(undefined);
    const [selectedVenue, setSelectedVenue] = React.useState<VenueDropdownListItem | null>(null);

    React.useEffect(() => {
        getAllVenues().then((data) => {
            console.log(data)
            if (data) {
                const dropdownVals = data.map((ven) => {
                    return ({
                        value: ven.id,
                        label: ven.name
                    })
                })
                console.log(dropdownVals)
                setVenueList(dropdownVals);
            } else {
                setVenueList([]);
                setVenueHasProblems(true);
            }
        });
    }, []);

    React.useEffect(() => {
        if (selectedVenue) {
            getVenueDetails(selectedVenue?.value, props.authToken, props.loggedInUser).then((data) => {
                if (data) {
                    setFullSelectedVenue(data);
                } else {
                    setFullSelectedVenue(undefined);
                    setVenueHasProblems(true);
                }
            })
        } else {
            setFullSelectedVenue(undefined);
        }

    }, [selectedVenue, props.loggedInUser, props.authToken])

    if (venueHasProblems) {
        return (<b>A networking error has occured. Please try again.</b>)
    }
    if (!venueList) {
        return (<b>Loading Venue List, please wait...</b>)
    }

    return (
        <div>
            <b>VenueViewer is Under Construction.</b>
        </div>
    )

    //TODO: {fullSelectedVenue && < VenueDetailedView venue={fullSelectedVenue} loggedInUser={props.loggedInUser} authToken={props.authToken} />}
    /*
    return (
        <div>
            <Select options={venueList}
                placeholder={"Select a Venue."}
                onChange={(val) => { return setSelectedVenue(val) }} />
            <b>Create a Venue: </b>
            <VenueUpdater loggedInUser={props.loggedInUser}
                authtoken={props.authToken} />

        </div>
    )
    */

}

export default VenueViewer;