import React, { useEffect, useRef, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment-timezone'
import "react-big-calendar/lib/css/react-big-calendar.css";
import '../styles/ClickableCalendar.css'
import EventDetailedView from './EventDetailedView';
import { useNavigate } from "react-router-dom";
import EventForm from './EventForm'
import { EventCreationDetails, EventSeriesProps, EventInfo } from '../types/eventModel';

export interface ClickableCalendarProps {
  events:EventInfo[];
  loggedInUser:number | undefined;
  authToken:string | undefined;
  onEventSubmit:(requestBody: EventCreationDetails | EventSeriesProps | EventInfo) => Promise<boolean>;
  onEventCancel:()=>void;
  onEventDelete:(requestBody: EventSeriesProps | EventInfo) => Promise<boolean>;
  onEventRSVPToggle:(eid: number) => Promise<boolean>;
}

export const ClickableCalendar:React.FC<ClickableCalendarProps> = (props) =>{
  const { events, loggedInUser, authToken, onEventCancel, onEventSubmit, onEventDelete, onEventRSVPToggle } = props
  const eventViewDialogRef = useRef<HTMLDialogElement>(null)
  const [selectedEvent, setSelectedEvent ] = useState<EventInfo>()
  const [eventFormReadOnly, setEventFormReadOnly] = useState(true)

  const navigate = useNavigate();
  
  eventViewDialogRef.current?.addEventListener('click', function(event) {
    var rect = eventViewDialogRef.current?.getBoundingClientRect();
    if(rect){
      var isInDialog = (rect.top <= event.clientY && event.clientY <= rect.top + rect.height &&
        rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
      if (!isInDialog) {
        eventViewDialogRef.current?.close();
      }
    }
  });

  const handleEventClick =(e:any)=>{
    e?.id && navigate('/events/eventpage/' + e.id)
  }

  const localHandleEventDelete = (e:EventSeriesProps|EventInfo)=>{
    eventViewDialogRef.current?.close()
    return onEventDelete(e)
  }

  const localHandleCancel = ()=>{
    setEventFormReadOnly(true); 
    setSelectedEvent(undefined); 
    onEventCancel();
    eventViewDialogRef.current?.close()
  }

  const localHandleSubmit = (e:any)=>{
    setEventFormReadOnly(true); 
    eventViewDialogRef.current?.close()
    return onEventSubmit(e)
  }

  moment.tz.setDefault('America/New_York');
  const localizer = momentLocalizer(moment);
  return (
    <div style={{height:'100%', width:'100%'}}>
      <Calendar
        style={{height:'100%', width:'100%'}}
        localizer={localizer}
        onSelectEvent={(e)=>handleEventClick(e)}
        events={events}
      />
      <dialog ref={eventViewDialogRef} 
        style={{zIndex:20, height:'75%', position:'absolute', top:100, width:'60%'}} 
        onClose={()=>{setEventFormReadOnly(true); setSelectedEvent(undefined); onEventCancel()}}
      >
        {selectedEvent &&
          <EventForm 
            event={selectedEvent}
            readonly={eventFormReadOnly}
            onCancel={localHandleCancel}
            loggedInUser={loggedInUser} 
            authToken={authToken} 
            onSubmit={localHandleSubmit}
            onDelete={localHandleEventDelete}
            onRequestEdit={()=>setEventFormReadOnly(false)}
            onRSVPToggle={onEventRSVPToggle}
          />
        }
      </dialog>
    </div>
  )
}