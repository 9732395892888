import React from 'react';
import { CltnTableProps } from '../../generic/tables/CltnTable';
import { AuthUser } from '../../../models/user/userAuthorization';
import { CltnTableRow, CltnTableRowProps } from '../../generic/tables/CltnTableRow';
import { toggleUserSessionStatus } from '../../../functions/Sessions';
import type * as CSS from 'csstype';
import { SessionSignUpStatus } from '../../../functions/Sessions';

// Session Collation Table

export interface SessionTableProps extends CltnTableProps 
{
	user: AuthUser,
	seshIds: number[],
	enableSignUp: boolean,
	seshSignUpStates: SessionSignUpStatus[],
	onSignUpChange: (sesh: number, signUpStatus: boolean) => Promise<boolean>,
}

export interface SessionTableRowProps extends CltnTableRowProps 
{
	user: AuthUser,
	seshId: number,
	enableSignUp: boolean,
	seshSignUpState: SessionSignUpStatus,
	onSignUpChange: (sesh: number, signUpStatus: boolean) => Promise<boolean>,
}

const SessionTableRow = (props: SessionTableRowProps) =>
{
	const [signUpStatus, setSignUpStatus] =React.useState<boolean>(props.seshSignUpState == SessionSignUpStatus.signedUp ? false: true);
	const [enableSignUp, setEnableSignUp] = React.useState<boolean>(props.seshSignUpState != SessionSignUpStatus.notPermitted);
	const buttonText = signUpStatus ? 'Sign Up' : 'Relinquish'; 

	const row = props.rowContent;
	const count = Number(row[row.length -1]);
	const removedCountRow = row.slice(0,-1);
	let rowStyle = (count % 2 == 0 ? 'sess' : 'sessAlt');

	if(!signUpStatus)
	{
		rowStyle += 'High';
	}

	React.useEffect(() => {
		setEnableSignUp(props.seshSignUpState != SessionSignUpStatus.notPermitted);
	}, [props.seshSignUpState]);
	

	React.useEffect(() => {
		setSignUpStatus(props.seshSignUpState == SessionSignUpStatus.signedUp ? false: true);
	}, [props.seshSignUpState]);

	const onSignUpClick = () => {
		//toggleUserSessionStatus(props.user, props.seshId ,signUpStatus).then((result) => {
		props.onSignUpChange(props.seshId ,signUpStatus).then((result) => {
			if(result)
			{
				setSignUpStatus(!signUpStatus);
			}
			else 
			{
				//Error Logic Here
				console.log('An error has occured when Signing up for sesh' + props.seshId);
			}
		})
	}

	return(
		<tr>
			{removedCountRow.map((val, rowID) =>
			(
				<td className={rowStyle} key={rowID}>{val}</td>
			))}

			<td className={rowStyle}>
				{enableSignUp && <button onClick={onSignUpClick}>{buttonText}</button>}
			</td>
		</tr>
	);
}





export const SessionTable: React.FC<SessionTableProps> = (props) =>
{
	return(
		<table className='sess'>
			<thead>
				<tr>
					{props.headings.map((head, headID) =>
					(
						<th className='sess' key={headID}>{head}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{props.body.map((rowContent, index) =>
				(
					<SessionTableRow onSignUpChange={props.onSignUpChange} seshSignUpState={props.seshSignUpStates[index]} enableSignUp={props.enableSignUp} rowContent={rowContent} key={index} seshId={props.seshIds[index]} user={props.user}/>
				))}
			</tbody>
		</table>
	);
}


export default SessionTable;