import React from 'react';
import './index.css';
import * as Pages from './components/pages';
import GamaMenu from './menu';

export default class Q12018 extends React.Component {
  state = {
    navState: "landing"
  }

  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state =
    {
      navState: "landing",
    };
  }



  navClick(newnav: string) {
    this.setState({ navState: newnav });
    console.log("Status is now " + newnav);
  }


  renderSelectedPage(selectedPage: string) {
    type pageType = keyof typeof Pages;
    
    let myMap = new Map<string, React.ElementType>();

    Object.keys(Pages).forEach((key) => { myMap.set(key.toString(), Pages[key as pageType]) })

    const Page: React.ElementType = myMap.get(selectedPage)!;

    return (<Page navcallback={(a: string) => this.navClick(a)} />);
  }



  render() {
    return (
      <React.Fragment>
        <div className="gamacea">
          <div className="containergama">
            <GamaMenu callback={(a) => this.navClick(a)} />
            <div className="infoBar">
              <p className="infoDate">
                Quarterly | 2018 Q1 | March 24th, 2018 | 10AM - Midnight
              </p>
              <p className="infoVenue">
                Courtyard Marriott Natick | 342 Speen Street, Natick, MA
              </p>
            </div>
            {this.renderSelectedPage(this.state.navState)}
          </div>
        </div>
      </React.Fragment>
    );
  }

}
