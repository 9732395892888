import React from "react";
import Select from 'react-select'
import { VenueDetails } from "../venueModels";
import '../index.css'


export interface VenueSelectorProps {
  venues:VenueDetails[]
  containerClassNames?:{'react-select-input'?:string, 'react-select-control'?:string, 'react-select-container'?:string};
  onChange:Function;
  selectedVenueId?:number
}

export const VenueSelector:React.FC<VenueSelectorProps> = (props) => {
  const { containerClassNames, onChange, venues, selectedVenueId } = props
  return (
      <Select
        classNames={{
          input:()=>(containerClassNames && containerClassNames['react-select-input'] || 'react-select-input'),
          control:()=>(containerClassNames && containerClassNames['react-select-control'] || 'react-select-control'),
          container:()=>(containerClassNames && containerClassNames['react-select-container'] || 'react-select-container')
        }}
        value={Array.isArray(venues) && selectedVenueId ? venues.find(v=>v.id===selectedVenueId):undefined}
        placeholder="Select a venue..."
        autoFocus
        blurInputOnSelect
        captureMenuScroll
        id='venues'
        defaultValue={undefined}
        onChange={(e)=>onChange(e)}
        options={venues}
        getOptionLabel={v=>v.name}
        getOptionValue={v=>v.id.toString()}
      />
  )
}

