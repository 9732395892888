import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./EventCalendar.css";
import React, { useMemo } from 'react';
import { CustomWeekView } from './CustomWeekView';
import EventCreator from './EventCreator';
// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer

export interface CalEvent {
    bgg_title_link?: string,
    owner: string,
    id: number,
    title: string,
    start: Date,
    end: Date,
    info: string,
    room: string,
    table: number,
    slots_available: number,
    participants: string[]
}

interface EventCalendarProps {
    eventList: CalEvent[],
    onRsvp: (evt: CalEvent) => Promise<boolean>,
    userStatus: number,
    userId: number,
    userName: string,
    onEventUp: () => Promise<boolean>,
    token: string,
    evtId: number
}

const EventCalendar = (props: EventCalendarProps) => {

    const user_status = props.userStatus;

    const [selectedEvent, setSelectedEvent] = React.useState<CalEvent>();

    const evtList = useMemo(
        () => (props.eventList),
        [props.eventList]
    );

    const { startDate, views } = useMemo(
        () => ({
            startDate: new Date(2024, 5, 15),
            views: {
                month: true,
                week: CustomWeekView,
                day: true
            }
        }),
        []
    );

    const eventSelected = (event: CalEvent): any => {
        setSelectedEvent(event);
    };

    const eventAddRequest = () => {
        props.onEventUp();
    }

    function RSVPrequest(event: CalEvent) {
        const result = props.onRsvp(event);
        result.then((state) => {
            if (state && selectedEvent) {
                const newparticipants = selectedEvent.participants
                newparticipants.push(props.userName);
                const newEvent = { ...selectedEvent, participants: newparticipants, slots_available: selectedEvent.slots_available - 1 }
                setSelectedEvent(newEvent);
            }
        })
    }

    const showRSVP = (event: CalEvent, user: string) => {
        if (event.participants.includes(user)) {
            return <b> You are already RSVPed to this Event!</b>
        }
        else {
            return <button
                onClick={() => RSVPrequest(event)}
            >Click here to RSVP</button>
        }
    }

    const user_header = (sta: number) => {
        console.log(selectedEvent);
        switch (sta) {
            case 0:
                return <b>You are not logged in! Log into TGA to access RSVP Features!</b>;
            case 1:
                return (<div><b>Hello new user! This is a Beta version of our new scheduler. Eventually, we will tie your registration with your user to permit you access automatically.
                    For now, please click this button to be granted access to RSVP-ability. Please do not click if you are not registered yet!</b> <button
                        onClick={() => eventAddRequest()}
                    >Click here to enable RSVP!</button></div>);
            case 2:
                return <b>You may now RSVP to events! To gain event creation permissions, please request in the discord channel or contact us through our contact form.</b>;
            case 3:
                return <b>You may now create Events!</b>;
            default:
                return <b>Something Bad has occured if you're seeing this.</b>

        }

    }

    const editPermitted = () => {
        if (selectedEvent && selectedEvent.owner === props.userName) {
            return true;
        } else {
            return false;
        }
    }

    return <div>
        <div>
            {user_header(user_status)}
            <br></br>
            <b>For more help regarding User Accounts and Game scheduling, click <a href="https://www.tabletopgamersalliance.com/files/Getting_Started_with_Your_TGA_Online_Account.pdf" target="_blank" rel="noreferrer">here!</a></b>
        </div>
        <div className="myCustomHeight">
            <Calendar
                date={startDate}
                localizer={localizer}
                startAccessor="start"
                endAccessor="end"
                view="week"
                views={views}
                events={evtList}
                onSelectEvent={eventSelected}
                toolbar={false}
            />
        </div>
        <br></br>
        {selectedEvent && <div>
            <b>Selected Event: </b>
            <p> {selectedEvent.title}</p>
            <b>Start Time: </b>
            <p> {selectedEvent.start.toLocaleString()}</p>
            <b>End Time: </b>
            <p> {selectedEvent.end.toLocaleString()}</p>
            {selectedEvent.bgg_title_link && <div>
                <b>BGG Game Link:</b><br></br><br></br>
                <a href={selectedEvent.bgg_title_link} target="_blank" rel="noreferrer">{selectedEvent.bgg_title_link}</a>
            </div>}
            <br></br>
            <b>Description:</b>
            <p> {selectedEvent.info}</p>
            <b>Room:</b>
            <p> {selectedEvent.room}</p>
            <b>Table:</b>
            <p> {selectedEvent.table}</p>
            <b>Event Runner:</b>
            <p> {selectedEvent.owner}</p>
            <b>Slots Available:</b>
            <p> {selectedEvent.slots_available}</p>
            <b>Current Participants:</b>
            <p> {selectedEvent.participants.join(' ,')}</p>
            {props.userStatus > 1 && showRSVP(selectedEvent, props.userName)}
        </div>}
        <br></br>
        {props.userStatus > 2 && !editPermitted() && <EventCreator evtId={props.evtId} userId={props.userId} token={props.token} />}
        {props.userStatus > 2 && editPermitted() && selectedEvent && <EventCreator  evtId={props.evtId} userId={props.userId} token={props.token} defaults={selectedEvent} editEventId={selectedEvent.id} key={selectedEvent.id} />}
    </div>
}

export default EventCalendar;