import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import AllianceApp from './AllianceApp';
import { BrowserRouter } from "react-router-dom"
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <AllianceApp />
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
