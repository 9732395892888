import "react-big-calendar/lib/css/react-big-calendar.css";
import React, { useMemo } from 'react';
import './collapsableContent.css'
// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.

interface CollapsableContentProps {
    title: string,
    startingIsCollapsed: boolean,
    children: any
}

const CollapsableContent = (props: CollapsableContentProps) => {

    const [isCollapsed, setIsCollapsed] = React.useState<boolean>(props.startingIsCollapsed);

    const handleClick = () => setIsCollapsed(!isCollapsed);

    const buttonText = isCollapsed ? " ▼ ": " - ";

    return <div className={isCollapsed ? "main_collapsed" : "main"}>
        <div className={isCollapsed ? "header_collapsed" : "header"} onClick={handleClick}>
            <p className="title">{props.title}</p>
            <p className="icon">{buttonText}</p>
        </div>
        {!isCollapsed && <div><main className="content_container">{props.children}</main></div>}
    </div>

}

export default CollapsableContent;
