import React from 'react';

const eventMap = (props: any) =>
(
  <aside className="eventMap">
    <header className="eventMap-header">
      <b>Commons Room</b>
      <p>The Commons room is a large function room the size of most of the rooms we have used for Quarterlies. This will be where the bulk of the open gaming will occur.</p>
      <b>Walden Room</b>
      <p>The Walden room is a smaller function room with 42 seats across 6 tables. This room will be reserved for scheduled games, scheduled teaching games, and games that need a quieter space away from the rumble and outbursts of the large open gaming area in the Commons. Please, play party/loud games in the Commons instead of the Walden.</p>
      <b>Eliot Room</b>
      <p>The Eliot room is sized between the Commons and Walden rooms. It has 52 seats across 8 tables plus just as much space for storing games as the Commons. This room's layout was designed with the intention of having more slightly more open space and mitigating our shortage of game storage space during peak activity.</p>
      <p>To sum it all up, we have 192 seats across 31 tables for the entire event!</p>
    </header>
    <main className="eventMap-body">
      <img className="eventMap" src="https://i.tabletopgamersalliance.com/q/q4/2019/Full-Layout.png" alt="" />
    </main>
    <footer className="eventMap-footer">
    </footer>
  </aside>
);

export default eventMap;
