import React from 'react';

const aboutpage = (props: any) =>
(
  <div>
  <p>Established on September 10th 2013 and formerly known as Metrowest Board Gaming, the Tabletop Gamers Alliance (TGA) is a group of gamers in lower New England that meet up to play strategy board games, deck-building games, LCG's, and the like. We originally started in Metrowest, but have begun expanding beyond those boundaries to serve gamers across Massachusetts, New Hampshire, and Connecticut.</p>
  <p>We have 700+ members and have special discounts arranged with one Friendly Local Game Store (FLGS).</p>
  <p>In between Quarterlies, we meet more frequently to game at two weekly meetups (every Sunday and Thursday), two fortnightly meetups during the week (every other Monday, Tuesday), four monthly meetups (on one Tuesday every four weeks, the first Thursday of the month, the third Thursday of the month, and the third Saturday of the month). The bulk of those events are for open gaming, but we run an X-Wing league and have occasional one-off events for specific games or even more open gaming.</p>
  <p><a href='https://www.meetup.com/TabletopGamersAlliance/' target="_blank"  rel="noreferrer">Join TGA on Meetup.com</a> in order to be informed of all of our events.</p>
  <p>If you are interested in:</p>
  <ul>
    <li>listing your events with us</li>
    <li>becoming an event host</li>
    <li>providing a venue for us to host events in</li>
    <li>sponsoring one of the Quarterlies or TGA as a group</li>
    <li>offering TGA a group discount</li>
    <li>exhibiting, vending, or demo'ing at one of the Quarterlies</li>
  </ul>
  <p>Then, please contact us via our <a href='https://www.meetup.com/TabletopGamersAlliance/' target="_blank"  rel="noreferrer">Meetup</a> or <a href='https://www.facebook.com/TabletopGamersAlliance' target="_blank"  rel="noreferrer">Facebook</a>.</p>
  </div>
);

export default aboutpage;
