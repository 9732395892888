import React from 'react';

const eventMap = (props: any) =>
(
  <aside className="eventMap">
    <header className="eventMap-header">
    </header>
    <main className="eventMap-body">
      {/*This year's map will be posted soon!*/}
      <img className="eventMap" src="https://www.tabletopgamersalliance.com/i/gamacea/2024/gamacea2024_numbered_layout.png" alt="" />
    </main>
    <footer className="eventMap-footer">
    </footer>
  </aside>
);

export default eventMap;
