import React, { useEffect, useState } from 'react'
import {
  useForm, FieldValues, SubmitHandler, Controller
} from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import Select, { SingleValue } from 'react-select';
import { ErrorMessage } from "@hookform/error-message"
import { EventCreationDetails, EventSeriesProps, EventInfo } from '../types/eventModel';
import '../styles/EventForm.css'
import { getAllVenues, GETAllVenues_Single, VenueDetails } from "../../venues/venueModels";
import { VenueMap } from '../../venues/components';
import DateTimePicker from 'react-datetime-picker';
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';
import '../styles/TimePicker.css';
import '../styles/DatePicker.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import { fetchSeriesById, fetchUserById, fetchVenues, fetchEventAttendees } from '../../../../utils';
import { VenueSelector } from '../../venues/components/VenueSelector';
import privateVenueMapPlaceholder from '../files/simple-house.jpg'
import { UserInfo } from 'os';
import { UserDetails } from '../../../../models/user/userInfo';
import '../../venues/index.css'; // is this the right style sheet?
import { isValueType } from 'react-date-picker/dist/cjs/shared/propTypes';
import { MoonLoader } from 'react-spinners'
import { getVenueDetails } from '../../venues/venueModels';

export interface eventFormProps {
  loggedInUser?: number,
  authToken?: string,
  containerClassname?: string,
  event?: EventSeriesProps | EventInfo,
  onRequestEdit?: ()=>void,
  onSubmit: (event: EventSeriesProps | EventCreationDetails | EventInfo) => any,
  onCancel: () => void,
  onDelete?: (requestBody: EventSeriesProps | EventInfo) => Promise<boolean>,
  onRSVPToggle: (eid: number) => Promise<boolean>,
  readonly?: Boolean,
  disableRsvp ?: Boolean
}

export const EventForm: React.FC<eventFormProps> = (props) => {
  const { containerClassname, event, onSubmit, authToken, loggedInUser, onCancel, onDelete, onRequestEdit, onRSVPToggle, readonly } = props
  const [isLoading, setIsLoading] = useState(true)
  const [formDataLoaded, setFormDataLoaded] = useState(false)
  const [venueOptions, setVenuesOptions] = useState<{label: string; value: VenueDetails;}[]>([])
  const [series, setSeries] = useState<EventSeriesProps>()
  const [instance, setInstance] = useState<EventInfo>()
  const [userDetails, setUserDetails] = useState<UserDetails>()
  const [selectedVenue, setSelectedVenue] = useState<VenueDetails | undefined>()
  const [eventAttendees, setEventAttendees] = useState<string[]|undefined>()
  const [isRecurring, setIsRecurring] = useState(false)
  const [isEditingSeries, setIsEditingSeries] = useState(true) // Are we editing an EventInfo or an EventSeriesProps?
  const [deleteConfirmDisplay, setDeleteConfirmDisplay] = useState<boolean>(false);
  const dateStringFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  useEffect(()=>setIsRecurring(getValues('recurrencePattern') !== 'ONCE'))
  const getDateString = (d: Date) => {return (d.getFullYear() + '-' + (d.getMonth() + 1).toString().padStart(2,"0") + '-' + d.getDate().toString().padStart(2,"0"))};
  
  var nowDate = new Date();




  
  const defaultValues = {
    name: 'My Event',
    info: '',
    detailed_directions: '',
    slots: 20,
    websiteUrl: '',
    eventStartTime: "9:00",
    eventEndTime: "17:00",
    instanceStartTime: nowDate.toISOString(),
    instanceEndTime: nowDate.toISOString(),
    eventsStart: nowDate.toISOString(),
    eventsEnd: nowDate.toISOString(),
    venueOption:null,
    recurrencePreset: { label: 'One time only', value: {recurrencePattern:'ONCE', recurrenceSeperation:undefined}},
    recurrencePattern: 'ONCE',
    recurrenceSeperation: 0,
    lowestTableNumber: 1,
    highestTableNumber: 4
  }





  const {
    formState: { errors, isValid }, getValues, register, setValue: setFormValue, handleSubmit, control, reset
  } = useForm<FieldValues>(
    {
      defaultValues: defaultValues,
      mode: 'onBlur'
    },
  );





  const recurrenceSelectOptions = [
    { label: 'one time only', value: {recurrencePattern:'ONCE', recurrenceSeperation:undefined}},
    { label: 'daily', value: {recurrencePattern:'DAILY', recurrenceSeperation:0}},
    { label: 'weekly', value: {recurrencePattern:'WEEKLY', recurrenceSeperation:0}},
    { label: 'biweekly', value: {recurrencePattern:'WEEKLY', recurrenceSeperation:1}},
    { label: 'monthly', value: {recurrencePattern:'MONTHLY', recurrenceSeperation:0} },
  ]





  const isEditingSeriesSelectOptions = [
    { label: 'Editing event series for', value: 'true' },
    { label: 'Editing single event', value: '' } // Empty string is falsy
  ]





  const loadEventAttendees = ()=>{
    if(event){
      fetchEventAttendees(event.id).then((r)=>{
        setEventAttendees(r)
    })
    }
  }





  const loadFormData = () => {
    // Fetches list of venues, and the event owner's username
    if (authToken && loggedInUser) {
      setIsLoading(true)
      Promise.all([
        event ? fetchEventAttendees(event.id) : Promise.resolve([]),
        fetchVenues(authToken),
        event && Object.keys(event).includes('series') && (event as EventInfo).series ? fetchSeriesById((event as EventInfo).series!, authToken) : Promise.resolve(undefined),
        fetchUserById(loggedInUser, authToken)
      ]).then(([attendees, venues, series, userInfo]) => {
        setEventAttendees(attendees)
        setVenuesOptions(venues.map(v=>({label:v.name, value:v})))
        setSeries(series)
        setUserDetails(userInfo)
        setFormDataLoaded(true)
      }).catch((err) => {
        setFormDataLoaded(false)
        console.error(`Error loading EventForm data: ${JSON.stringify(err)}`)
      }).finally(() => {
        setIsLoading(false)
      })
    } else {
      setIsLoading(true)
      Promise.all([
        event ? fetchEventAttendees(event.id) : Promise.resolve([]),
        event ? getVenueDetails(event.venue) : Promise.resolve(undefined),
        event && Object.keys(event).includes('series') && (event as EventInfo).series ? fetchSeriesById((event as EventInfo).series!, authToken) : Promise.resolve(undefined),
      ]).then(([attendees, venue, series]) => {
        console.log(venue)
        setEventAttendees(attendees)
        venue && setVenuesOptions([{label: venue.name, value: venue}])
        setSeries(series)
        setFormDataLoaded(true)
      }).catch((err) => {
        setFormDataLoaded(false)
        console.error(`Error loading EventForm data: ${JSON.stringify(err)}`)
      }).finally(() => {
        setIsLoading(false)
      })
    }
  }





  useEffect(() => {
    loadFormData()
    if (authToken && loggedInUser) {
    } else {
      console.error('Creating or editing events requires event host permission.')
    }
  }, [event, authToken, loggedInUser])




  
  useEffect(() => {
    // If existing event is supplied, determine if it is a series or an instance
    if (event && Object.keys(event).includes('eventsStart')) {
      setSeries(event as EventSeriesProps)
      setIsEditingSeries(true)
    } else if (event) {
      setInstance(event as EventInfo)
      setIsEditingSeries(false)
    }
  }, [event])





  useEffect(() => {
    // Populate form values with existing data (if any)
    if (series && isEditingSeries) {
      // Editing existing series
      setFormValue('highestTableNumber', series.highestTableNumber)
      setFormValue('info', series.info)
      setFormValue('lowestTableNumber', series.lowestTableNumber)
      setFormValue('name', series.name)
      setFormValue('slots', series.slots)
      const seriesVenue = venueOptions.find(o=>o.value.id === series?.venue)?.value
      setSelectedVenue(seriesVenue)
      setFormValue('venueOption', {label:seriesVenue?.name, value:seriesVenue})
      setFormValue('websiteUrl', series.websiteUrl)
      setFormValue('eventStartTime', series.eventStartTime)
      setFormValue('eventEndTime', series.eventEndTime)
      setFormValue('recurrencePreset', recurrenceSelectOptions.find(o=>o.value.recurrencePattern === series.recurrencePattern && o.value.recurrenceSeperation === series.recurrenceSeperation))
      setFormValue('recurrencePattern', series.recurrencePattern)
      setFormValue('recurrenceSeperation', series.recurrenceSeperation)
      setFormValue('eventsStart', series.eventsStart+'T00:00:00') //add a time to make datestring represent localtime
      setFormValue('eventsEnd', series.eventsEnd+'T00:00:00') //add a time to make datestring represent localtime
      setIsRecurring(!!series.recurrencePattern)
    } else if (instance && !isEditingSeries) {
      // Editing existing instance
      setFormValue('highestTableNumber', instance.highestTableNumber)
      setFormValue('info', instance.info)
      setFormValue('lowestTableNumber', instance.lowestTableNumber)
      setFormValue('name', instance.name)
      setFormValue('slots', instance.slots)
      const instanceVenue = venueOptions.find(o=>o.value.id === instance?.venue)?.value
      setSelectedVenue(instanceVenue)
      setFormValue('venueOption', {label: instanceVenue?.name, value:instanceVenue})
      setFormValue('websiteUrl', instance.websiteUrl)
      setFormValue('instanceStartTime', new Date(instance.startTime))
      setFormValue('instanceEndTime', new Date(instance.endTime))
    }

    // Else, creating new event, use form defaults
  }, [series, setFormValue, instance, formDataLoaded, isEditingSeries])





  const handleVenueSelect = (o:{label:string, value: VenueDetails}) => {
    setSelectedVenue(o.value)
  }





  const handleRecurrencePatternChange = (e: any) => {
    setIsRecurring(e.value.recurrencePattern !== 'ONCE')
    setFormValue('recurrencePattern', e?.value.recurrencePattern)
    setFormValue('recurrenceSeperation', e?.value.recurrenceSeperation)
    const eventsStartDate = new Date(getValues('eventsStart'))
    switch (e.value.recurrencePattern) {
      case 'ONCE':
        setFormValue('eventsEnd', getValues('eventsStart'))
        break;
      case 'DAILY':
        const dateForNextDay = new Date(eventsStartDate.getTime() + 1 * 24 * 60 * 60 * 1000)
        setFormValue('eventsEnd', dateForNextDay.toISOString())
        break;
      case 'WEEKLY':
        const dateForNextWeek = new Date(eventsStartDate.getTime() + 7 * 24 * 60 * 60 * 1000)
        setFormValue('eventsEnd', dateForNextWeek.toISOString())
        break;
      case 'BIWEEKLY':
        const dateForTwoWeeks = new Date(eventsStartDate.getTime() + 14 * 24 * 60 * 60 * 1000)
        setFormValue('eventsEnd', dateForTwoWeeks.toISOString())
        break;
    }
  }





  const handleChangeBetweenEditingInstanceOrSeries = async (o:any)=>{
    const val = !!o?.value
    if(val){
      // now editing series
      await loadFormData()
      setIsEditingSeries(val)
    }else{
      // no editing instance
      setIsEditingSeries(val)
    }
  }





  const localOnSubmit = (fields: FieldValues) => {
    /* transforms field values to EventSeriesProps or
      EventCreationSchema before calling props.onSubmit */
    if (isEditingSeries && series) {
      // Editing existing event series, submit EventSeriesProps
      onSubmit({
        id: series.id,
        info: fields.info,
        name: fields.name,
        owner: series.owner,
        slots: fields.slots,
        eventsStart: fields.eventsStart.split("T")[0],
        eventsEnd: fields.eventsEnd.split("T")[0],
        eventStartTime: fields.eventStartTime,
        eventEndTime: fields.eventEndTime,
        recurrencePattern: fields.recurrencePattern,
        recurrenceSeperation: fields.recurrenceSeperation,
        venue: fields.venueOption.value.id,
        websiteUrl: fields.websiteUrl,
        lowestTableNumber: fields.lowestTableNumber, // To be implemented
        highestTableNumber: fields.highestTableNumber, // To be implemented
      } as EventSeriesProps)
    } else if (!isEditingSeries && instance) {
      // Editing instance event, submit EventInfo
      onSubmit({
        endTime: fields.instanceEndTime,
        highestTableNumber: fields.highestTableNumber, // To be implemented
        id: instance.id,
        info: fields.info,
        lowestTableNumber: fields.lowestTableNumber,
        name: fields.name,
        owner: instance.owner,
        slots: fields.number,
        series: instance.series,
        startTime: fields.instanceStartTime,
        venue: fields.venueOption.value.id,
        websiteUrl: fields.websiteUrl
      } as EventInfo)
    } else {
      // Creating new event, submit EventCreationDetails
      onSubmit({
        info: fields.info,
        name: fields.name,
        slots: fields.slots,
        eventsStart: fields.eventsStart.split("T")[0],
        eventsEnd: (fields.recurrencePattern === 'ONCE') ? fields.eventsStart.split("T")[0] : fields.eventsEnd.split("T")[0], // Set eventsEnd = eventsStart for non-recurring event
        eventStartTime: fields.eventStartTime,
        eventEndTime: fields.eventEndTime,
        recurrencePattern: fields.recurrencePattern,
        recurrenceSeperation: fields.recurrenceSeperation,
        venue: fields.venueOption.value.id,
        websiteUrl: fields.websiteUrl,
        lowestTableNumber: fields.lowestTableNumber, // To be implemented
        highestTableNumber: fields.highestTableNumber, // To be implemented
        rsvp: fields.rsvp
      } as EventCreationDetails)
    }
    reset(defaultValues)
  }





  const localOnDelete = (event:EventInfo | EventSeriesProps)=>{
    if(onDelete){
      if(isEditingSeries && series){
        onDelete(series)
      }else{
        onDelete(event)
      }
    }
  }





  const localHandleRSVPToggle =()=>{
    if(instance){
      onRSVPToggle(instance.id).then(()=>(
        loadEventAttendees()
      ))
    }else{
      console.error('EventForm.localHandleRSVPToggle: event instance not found')
    }
  }





  if(isLoading){
    return (
      <div style={{display:'flex', alignItems:'center', justifyContent:'center', height:'100%', width:'100%'}}>
        <MoonLoader/>
      </div>
    )
  }
  




  return (
    <form
      onSubmit={handleSubmit(localOnSubmit)}
      className={containerClassname || 'event-form'}
    >
      <div className='event-form-first-row-container'>
        {event && !readonly ?
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                flexGrow: 1,
                width: 250,
                marginRight: 8
              }),
            }}
            defaultValue={{ label: 'Editing event series for', value: 'true' }}
            options={isEditingSeriesSelectOptions}
            onChange={(o:any)=>handleChangeBetweenEditingInstanceOrSeries(o)}
            value={event && isEditingSeries ?
              isEditingSeriesSelectOptions[0] :
              isEditingSeriesSelectOptions[1]
            }
          />
          :
          !event && <h4 className='controls-row' style={{ marginRight: 8 }}>Creating new event</h4>
        }
        {!readonly ? <input
          style={{ textAlign: 'center', width: '75%', height: 30 }}
          className='event-form-item'
          type="text"
          placeholder="Event Name"
          {...register("name", {
            required: 'Event name is required'
          })}
        /> : <h3 className='controls-row read-only-field' style={{ marginRight: 8 }}>{event?.name}</h3>}
        <ErrorMessage
          errors={errors}
          name="name"
          render={({ message }) => <p className='helper-text'>{message}</p>}
        />
      </div>
      <div className='event-form-second-row-container'>
        {isEditingSeries &&
          <div id='event-form-time-controls-for-series' className='controls-row'>
            <h4 style={{ textAlign: 'center' }}>from</h4>
            {!readonly ? <Controller
              control={control}
              name='eventStartTime'
              render={({ field:{onChange, value} }) => (
                <TimePicker clockIcon={null} onChange={onChange} value={value} />
              )}
            /> : <h4 className='read-only-field'>{(event as EventSeriesProps)?.eventStartTime?.toLocaleTimeString()}</h4>}
            <h4 style={{ textAlign: 'center' }}>to</h4>
            {!readonly ? <Controller
              control={control}
              name='eventEndTime'
              render={({ field:{value, onChange} }) => (
                <TimePicker clockIcon={null} onChange={e=> e ? onChange(e) : undefined} value={value} />
              )}
            /> : <h4 className='read-only-field'>{(event as EventSeriesProps)?.eventEndTime?.toLocaleTimeString()}</h4>}
            <h4 style={{ textAlign: 'center' }}>{isEditingSeries ? "starting on" : "on"}</h4>
            {!readonly ? <Controller
              control={control}
              name='eventsStart'
              render={({ field:{value, onChange}}) => (
                <DatePicker locale="en-US" onChange={e=> e ? onChange((e as Date).toISOString()) : undefined} value={value} />
              )}
            /> : <h4 className='read-only-field'>{(event as EventSeriesProps)?.eventsStart?.toDateString()}</h4>}
          </div>
        }
        {!isEditingSeries &&
          <div id='event-form-time-controls-for-instance' className='controls-row'>
            <h4 style={{ textAlign: 'center' }}>from</h4>
            {!readonly ? <Controller
              control={control}
              name='instanceStartTime'
              render={({ field:{value, onChange}}) => {
                return (
                  <DateTimePicker locale="en-US" onChange={onChange} value={value} />
                )
              }}
            /> : <h4 className='read-only-field'>{getValues('instanceStartTime').toLocaleString()}</h4>}
            <h4 style={{ textAlign: 'center' }}>to</h4>
            {!readonly ? <Controller
              control={control}
              name='instanceEndTime'
              render={({ field:{value, onChange}}) => {
                return (
                  <DateTimePicker locale="en-US" onChange={onChange} value={value} />
                )
              }}
            /> : <h4 className='read-only-field'>{getValues('instanceEndTime').toLocaleString()}</h4>}
          </div>
        }
      </div>

      <div className='event-form-third-row-container'>
        {isEditingSeries &&
          <div id='second-row-controls-series' className='controls-row'>
            <div className='controls-row'>
              <h4 style={{ textAlign: 'center' }}>occurring</h4>
              <Controller
                control={control}
                name='recurrencePreset'
                render={({ field:{onChange, value} }) => {
                  return (
                    <Select
                      options={recurrenceSelectOptions}
                      defaultValue={{ label: 'One time only', value: {recurrencePattern:'ONCE', recurrenceSeperation:0}}}
                      onChange={(e)=>{onChange(e); handleRecurrencePatternChange(e)}}
                      value={value}
                    />
                  )
                }}
              />
            </div>

            {isRecurring &&
              <div className='controls-row'>
                <h4 style={{ textAlign: 'center' }}>until</h4>
                <Controller
                  control={control}
                  name='eventsEnd'
                  render={({ field:{onChange, value} }) => (
                    <DatePicker locale="en-US" onChange={e=>e ? onChange((e as Date).toISOString()) : undefined} value={value} />
                  )}
                />
              </div>
            }
          </div>
        }
        <div className='controls-row' style={{ flexGrow: 1 }}>
          <h4>at</h4>
          { !readonly ? <Controller
            rules={{ required: true }}
            control={control}
            name='venueOption'
            render={({ field:{value, onChange} }) => {
              return (
                <Select
                  classNames={{
                    input:()=>('react-select-input'),
                    control:()=>('react-select-control'),
                    container:()=>('react-select-container')
                  }}
                  value={value}
                  placeholder="Select a venue..."
                  blurInputOnSelect
                  captureMenuScroll
                  id='venue-select'
                  onChange={(o:{label:string, value:VenueDetails})=>{onChange(o); handleVenueSelect(o)}}
                  options={venueOptions}
                />
              )
            }}
          /> 
          : <h4 className='read-only-field'>{(event && event.name === "Titan Buster") ? "Private Venue" : selectedVenue?.name}</h4>
          }
        </div>
      </div>
      <div className='event-form-fields'>
        { !readonly ? <textarea
          style={{ flexGrow: 1 }}
          className='event-form-item'
          placeholder='Introduction and Description'
          {...register("info", {
            required: false
          })}
        /> : <p className="read-only-field" style={{height:'100%', margin:0}}>{getValues('info')}</p>}
        <label>
          {`Hosted By: ${userDetails?.user_name}`}
        </label>
        <label>
          Max Participants:{
          !readonly ? <div style={{ display: 'inline-block' }}>

            <input
              type="number"
              min="1"
              max="100"
              className='event-form-item'
              style={{ textAlign: 'center', width: 60 }}
              {...register("slots", {
              })}
            >
            </input>
            
          </div> : <span className='read-only-field'>{getValues('slots')}</span>}
        </label>
        <label>
          Lowest Table Number:{
          !readonly ? <div style={{ display: 'inline-block' }}>

            <input
              type="number"
              min="1"
              max="25"
              className='event-form-item'
              style={{ textAlign: 'center', width: 60 }}
              {...register("lowestTableNumber", {
              })}
            >
            </input>
            
          </div> : <span className='read-only-field'>{getValues('lowestTableNumber')}</span>}
        </label>
        <label>
          Highest Table Number:{
          !readonly ? <div style={{ display: 'inline-block' }}>

            <input
              type="number"
              min="1"
              max="25"
              className='event-form-item'
              style={{ textAlign: 'center', width: 60 }}
              {...register("highestTableNumber", {
              })}
            >
            </input>
            
          </div> : <span className='read-only-field'>{getValues('highestTableNumber')}</span>}
        </label>
        { !isEditingSeries &&
        <div>
          <label>
            {`Attendees: `}
          </label>
          <em>
            {eventAttendees}
          </em>
        </div>

        }
      </div>
      <div className='event-form-venue'>
        {
          //TODO: Remove Hardcoded reliance on Titan Buster
          !selectedVenue || (selectedVenue?.private) || (event && event.name === "Titan Buster") ?
            <div className='event-form-venue-placeholder'>
              <img className='event-form-venue-placeholder-image' src={privateVenueMapPlaceholder} />
              {selectedVenue || getValues('venueOption') ?
                <h4 style={{ textAlign: 'center', margin: '.25em', whiteSpace: 'pre-wrap' }}>{"This is a private venue. \n Please message event host for address."}</h4>
                :
                <h4 style={{ textAlign: 'center', margin: '.25em', whiteSpace: 'pre-wrap' }}>{"No venue selected. Please select a venue."}</h4>
              }
            </div>
            :
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
              <h4 style={{ textAlign: 'center' }}>{selectedVenue?.agg_address}</h4>
              <VenueMap containerClassName='event-form-venue-map' venue={selectedVenue} />
            </div>
        }
      </div>
      {!readonly ? <div className='event-form-controls'>
      {!deleteConfirmDisplay && <div className='event-form-controls'>
        { (event?.id || series?.id) &&
        <div>
        <button
          type='button'
          className='event-form-button'
          onClick={() => setDeleteConfirmDisplay(true)}
        >
          {isEditingSeries ? "Delete Event Series" : "Delete Event"}
        </button>
        </div>
        }
        <button
          type='button'
          value='Cancel'
          className='event-form-button'
          onClick={()=>{reset(defaultValues); onCancel()}}
        >
          Cancel
        </button>
        <button
          disabled={!isValid}
          style={{ backgroundColor: isValid ? 'grey' : 'darkgrey'}}
          value='Submit'
          className='event-form-button'
          type="submit"
        >
          {isValid ? 'Submit' : <abbr title='All fields are required'>Submit</abbr>}
        </button>
      </div>}
      {deleteConfirmDisplay && <div className='event-form-controls'>
          <p>Are you sure you want to delete?</p>
          <button
            type='button'
            className='event-form-button'
            style={{ backgroundColor: '#ae2515' , color: 'white' }}
            onClick={()=>event ? localOnDelete(event):console.warn('onDelete missing event or handler.')}
          > Yes </button>
          <button
            type='button'
            className='event-form-button'
            onClick={()=>setDeleteConfirmDisplay(false)}
          > No </button>
          </div>}
      </div>
      :
      <div className='event-form-controls'>
        { loggedInUser && userDetails?.user_name === event?.owner &&
          <button
            type='button'
            className='event-form-button'
            onClick={ onRequestEdit ? onRequestEdit : ()=>console.warn('EventForm onRequestEdit called, but not defined')}
          >
            Edit
          </button>
        }
        { loggedInUser && !props.disableRsvp &&
          <button
            type='button'
            className='event-form-button'
            onClick={localHandleRSVPToggle}
          >
            {userDetails?.user_name && eventAttendees?.includes(userDetails.user_name) ? 'Cancel RSVP' : 'RSVP'}
          </button>
        }
      </div>}
    </form>
  )
}

export default EventForm