import React from 'react';
import './index.css';
import { isUserTitanBusterVerified }from "../../../../models/user/userAuthorization"

interface IndexProps
{
    loggedInUser?: number,
    authToken?: string,
}





const notLoggedIn = (props: any) : JSX.Element =>
(
    <div>
        <h2>
            .
        </h2>
        <br /><br />
        <h2>
            .
        </h2>
    </div>
);





const loggedInNotVerified = (props: any) : JSX.Element =>
(
    <div>
        <h2>
            If you have recieved a registration, please use the attached URL to purchase a registration.
        </h2>
        <br /><br />
        <h2>
            Then click here to verify your registration!
        </h2>
    </div>
);





const loggedInVerified = (props: any) : JSX.Element =>
(
    <div>
        <h2>
            You have validated your registration! Click here for the main event page!
        </h2>
    </div>
);





const footerElement1234 = (props: IndexProps) =>
{
    if (props.loggedInUser === undefined || props.authToken === undefined) {
        return notLoggedIn;
    }
    const validCheck = isUserTitanBusterVerified(props.authToken, props.loggedInUser).then((result) => {
        return result ? loggedInVerified : loggedInNotVerified 
    })
}





const Index = (props: IndexProps) =>
{
    const [validationState, setvalidationState] = React.useState<number>(0);

    const footerElement = (state: number) =>
    {
        if (state===0) {
            return (notLoggedIn);
        }
        if (state === 1) {
            return (loggedInNotVerified);
        }
        return (loggedInVerified);
    }
    
    return(
  <aside className="landing">
    <header className="landing-header">
    </header>
    <main className="landing-body">
      <img className="titan-logo" src="https://www.tabletopgamersalliance.com/i/TitanBuster/2024/TitanBuster_splash.png" alt="" />
      <br /><br />
      <div className="gamacea-index-left">
        <h1>
            Greater Philidelphia
            August 16 - 18 2024 $80
            <br /><br />
            PLANET BRINDELL NEEDS YOU!
        </h1>
        <br /><br />
        <h2>
            EMERGENCY GALACTIC ALLIANCE CABLE:
        </h2>
        <p>
            A titan is attacking Brindell and, due to historically low staffing
            levels, the Alliance is calling on all willing individuals to
            register in order to provide support during the imminent battle.

            You should have already received registration instructions from the
            Alliance to your preferred communication account if you recorded
            any with Alliance recruiters over the past year.

            Alliance Research is still determining the type of titan that is
            attacking Brindell.  However, previous battle data indicates that
            the following types of titans attack ocean planets:
            
            <div>
                <table>
                    <tr>
                        <td>
                            1817
                        </td>
                        <td>
                            Twilight Imperium
                        </td>
                    </tr>
                    <tr>
                        <td>
                            18OE
                        </td>
                        <td>
                            Dune
                        </td>
                    </tr>
                    <tr>
                        <td>
                            CoIn
                        </td>
                        <td>
                            High Frontier
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Mega Civilization
                        </td>
                        <td>
                            Roads & Boats
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Virgin Queen
                        </td>
                        <td>
                            Alliance Research adds more as reports arrive from throughout the galaxy!
                        </td>
                    </tr>
                </table>
            </div>

            The Alliance urges you to follow the registration instructions
            immediately so that you can access briefing documents and
            participate in battle strategy sessions.

            FOR BRINDELL!
        </p>
      </div>
    </main>
    <footer className="landing-footer">
        <div>
        {/*{footerElement(validationState)}*/}
        </div>
    </footer>
  </aside>
    )
};

export default Index;