import React from 'react';
import { validateTitanBusterRegistration, isTitanBusterValidate }from "../../../../models/TitanBuster/TitanBusterRegistration"
import { useNavigate } from "react-router-dom";
import './validation.css'

interface ValidationProps
{
    loggedInUser?: number,
    authToken?: string,
}

const Validation = (props: ValidationProps) =>
{
    const [validationEmail, setValidationEmail] = React.useState<string>("");
    const [recieptId, setRecieptId] = React.useState<string>("");
    const [statusPrimary, setStatusPrimary] = React.useState<string>("");
    const [statusPlusOne, setStatusPlusOne] = React.useState<string>("");
    const [statusMsg, setStatusMsg]= React.useState<string>("Now Loading...");
    const [sucessfulValidation, setSucessfulValidation] = React.useState<boolean>(false);
    const [themePreference, setThemePreference] = React.useState<string>("Galactic Alliance Military");
    const [badgeNamePreference, setBadgeNamePreference] = React.useState<string>("TGA User Name");
    const [customName, setCustomName] =  React.useState<string>("");
    const [badgePronouns, setBadgePronouns] = React.useState<string>("");
    const [isCompanion, setIsCompanion] = React.useState<boolean>(false);
    const [companionEmail, setCompanionEmail] = React.useState<string>("");
    const [companionThemePreference, setCompanionThemePreference] = React.useState<string>("Galactic Alliance Military");
    const [companionName, setCompanionName] = React.useState<string>("");
    const [companionPronouns, setCompanionPronouns] = React.useState<string>("");
    const [discordName, setDiscordName] = React.useState<string>("");
    const [discordPlusOneName, setDiscordPlusOneName] = React.useState<string>("");

    const navigate = useNavigate()

    const display_msg = () => {
        if (recieptId === "" || validationEmail === "") {
            return "Please enter a receipt ID and Email Address above."
        }
        if (badgeNamePreference === "Custom" && customName === "") {
            return "Please enter your custom name"
        }
        if (isCompanion === true && companionName === "") {
            return "Please provide a Badge Name for your +1"
        }
        return statusMsg; 
    }

    const submit_validation = () => {
        if(props.loggedInUser === undefined){
            return; //Shouldn't happen
        }
        const input = {
            user: props.loggedInUser,
            receiptId: recieptId,
            email: validationEmail,
            theme_choice: themePreference,
            badge_name_type: badgeNamePreference,
            discord_id: discordName,
            custom_name : badgeNamePreference === "Custom" ? customName : undefined,
            badge_pronouns: badgePronouns,
            plus_one : isCompanion ? {
                email_address : companionEmail,
                theme_choice: companionThemePreference,
                custom_name: companionName,
                badge_pronouns: companionPronouns,
                discord_id: discordPlusOneName
            } : undefined
        }
        validateTitanBusterRegistration(input).then(value => {
            console.log(value)
            let primaryOk;
            let plusOneOk;
            if(isTitanBusterValidate(value)) {
                if(value.primary.result === "success") {
                    setStatusPrimary("The primary invitee has been sucessfully validated!");
                    primaryOk= true;
                    setSucessfulValidation(true);
                }
                else {
                    primaryOk = false;
                    if(value.primary.reason == "Companion-only email") {
                        setStatusPrimary("This email address is for a plus one only.")
                    }
                    if(value.primary.reason == "Registration already validated") {
                        setStatusPrimary("The primary invitee has already been validated for this order.")
                    }
                }
                if(value.plusOne.result === "success") {
                    plusOneOk = true;
                    setStatusPlusOne("The Plus One invitee has been sucessfully validated!");
                    setSucessfulValidation(true);
                }
                else {
                    plusOneOk = false;
                    if(value.plusOne.reason == "No +1 found") {
                        setStatusPlusOne("This registration does not have a plus one attached.")
                    }
                    if(value.plusOne.reason == "+1 registration used") {
                        setStatusPlusOne("The plus one invitee has already been validated for this order.")
                    }
                    if(value.plusOne.reason == "No +1 Data Provided") {
                        setStatusPlusOne("No plus one data has been provided. You can reuse this form if the situation changes.")
                    }
                }
                if(primaryOk || plusOneOk) {
                    setStatusMsg("This form will automatically redirect in a few seconds...")
                }
                else {
                    setStatusMsg("No registration has been validated. Please double check your entries.")
                }
            }
            else {
                setStatusMsg(value ? value.reason : "Bad Status");
                setSucessfulValidation(false);
            }
        });
    }

    React.useEffect(() => {
        if(sucessfulValidation) {
            setTimeout(() => {
                navigate("/TitanBuster/temp_schedule")
              }, 10000)
        }
      }, [sucessfulValidation])

    if(props.loggedInUser === undefined){
        return(
            <p>You need to be logged in to use this page!</p>
        )
    }

    return(
        <div>
            <p>Titan Buster Registration Validation</p>
            <p>Receipt ID</p>
            <input name="receiptId"
                className="smallInput"
                placeholder="Receipt ID"
                value={recieptId}
                onChange={(val) => { setRecieptId(val.target.value); setStatusMsg("") }} />
            <p>Order Email Address</p>
            <input name="emailAddress"
                className="smallInput"
                placeholder="Email Address"
                value={validationEmail}
                onChange={(val) => { setValidationEmail(val.target.value); setStatusMsg("") }} />
            <p>Theme Participation Choice</p>
            <input 
                type="radio"
                id="theme_military"
                value="Galactic Alliance Military"
                checked={ 
                    themePreference === 
                    "Galactic Alliance Military"
                } 
                onChange={() => {
                    setThemePreference( 
                        "Galactic Alliance Military"
                    ) 
                    setStatusMsg("");
                    setStatusPrimary("");
                    setStatusPlusOne("");
                    }
                } 
            /> 
            <label>Galactic Alliance Military</label> 
            <br></br>
            <br></br>
            <input 
                type="radio"
                id="theme_civilian"
                value="Extraordinary Citizen"
                checked={ 
                    themePreference === 
                    "Extraordinary Citizen"
                } 
                onChange={() => {
                    setThemePreference( 
                        "Extraordinary Citizen"
                    ) 
                    setStatusMsg("");
                    setStatusPrimary("");
                    setStatusPlusOne("");
                    }
                } 
            /> 
            <label>Extraordinary Citizen</label> 
            <p>Badge Name</p>
            <input 
                type="radio"
                id="name_user_name"
                value="TGA User Name"
                checked={ 
                    badgeNamePreference === 
                    "TGA User Name"
                } 
                onChange={() => {
                    setBadgeNamePreference( 
                        "TGA User Name"
                    );
                    setCustomName(""); 
                    setStatusMsg("");
                    setStatusPrimary("");
                    setStatusPlusOne("");
                    }
                } 
            /> 
            <label>TGA User Name</label> 
            <br></br>
            <br></br>
            <input 
                type="radio"
                id="name_full_name"
                value="Full Name"
                checked={ 
                    badgeNamePreference === 
                    "Full Name"
                } 
                onChange={() => {
                    setBadgeNamePreference( 
                        "Full Name"
                    ) 
                    setCustomName(""); 
                    setStatusMsg("");
                    setStatusPrimary("");
                    setStatusPlusOne("");
                    }
                } 
            /> 
            <label>Full Name</label> 
            <br></br>
            <br></br>
            <input 
                type="radio"
                id="name_first_name"
                value="First Name"
                checked={ 
                    badgeNamePreference === 
                    "First Name"
                } 
                onChange={() => {
                    setBadgeNamePreference( 
                        "First Name"
                    ) 
                    setCustomName("");
                    setStatusMsg("");
                    setStatusPrimary("");
                    setStatusPlusOne("");
                    }
                } 
            /> 
            <label>First Name Only</label> 
            <br></br>
            <br></br>
            <input 
                type="radio"
                id="name_last_name"
                value="Last Name"
                checked={ 
                    badgeNamePreference === 
                    "Last Name"
                } 
                onChange={() => {
                    setBadgeNamePreference( 
                        "Last Name"
                    ) 
                    setCustomName(""); 
                    setStatusMsg("");
                    setStatusPrimary("");
                    setStatusPlusOne("");
                    }
                } 
            /> 
            <label>Last Name Only</label> 
            <br></br>
            <br></br>
            <input 
                type="radio"
                id="name_custom"
                value="Custom"
                checked={ 
                    badgeNamePreference === 
                    "Custom"
                } 
                onChange={() => {
                    setBadgeNamePreference( 
                        "Custom"
                    ) 
                    setCustomName(""); 
                    setStatusMsg("");
                    setStatusPrimary("");
                    setStatusPlusOne("");
                    }
                } 
            /> 
            <label>Custom</label> 
            <br></br>
            <br></br>
            {badgeNamePreference === "Custom" && <input name="customName"
                className="smallInput"
                placeholder="Custom Name"
                disabled={badgeNamePreference != 
                    "Custom"}
                value={customName}
                onChange={(val) => { 
                    setCustomName(val.target.value); 
                    setStatusMsg("");
                    setStatusPrimary("");
                    setStatusPlusOne("");}} />}
            <p>Badge Pronouns (Optional)</p>
            <input name="badgePronouns"
                className="smallInput"
                placeholder="Badge Pronouns"
                value={badgePronouns}
                onChange={(val) => { 
                    setBadgePronouns(val.target.value); 
                    setStatusMsg("");
                    setStatusPrimary("");
                    setStatusPlusOne("");
                    }} />
            <br></br>
            <div className="discord_border">
                <p>(If you do not have one, <a href="https://discord.com" target="_blank" rel="noreferrer">create a free Discord account.</a> Next, <a href="https://discord.gg/gW3TTqeT3z" target="_blank" rel="noreferrer">join the TGA Discord server.</a>  Then, provide your Discord User ID: <a href="https://www.youtube.com/watch?v=SNxNNpiRR1M" target="_blank" rel="noreferrer">Desktop</a> / <a href="https://www.youtube.com/watch?v=BGFg7wT-hGE" target="_blank" rel="noreferrer">mobile.</a>)</p>
                <a href="https://discord.gg/gW3TTqeT3z" target="_blank" rel="noreferrer"></a>
                <input 
                    className="smallInput"
                    id="discord"
                    value={discordName}
                    placeholder="Discord User Id"
                    onChange={(val) => {
                        setDiscordName(val.target.value); 
                        setCustomName(""); 
                        setStatusMsg("");
                        setStatusPrimary("");
                        setStatusPlusOne("");
                        }
                    } 
                /> 
                <p>Providing your Discord User ID to us will allow us to give you access to the chat channels where you can discuss travel, gaming, and dining plans with other Titan Buster attendees and staff.</p>
            </div>
            <br></br>
            <br></br>
            <hr/>
            <input 
                type="checkbox"
                id="isComapnion"
                value="isCompanion"
                checked={ 
                    isCompanion === 
                    true
                } 
                onChange={(val) => {
                    setIsCompanion( 
                        val.target.checked
                    ) 
                    setStatusMsg("");
                    setStatusPrimary("");
                    setStatusPlusOne("");
                    }
                } 
            />
            <label>I have a +1 who will NOT have a TGA Account and would like to Validate their registration</label>
            {isCompanion && <div>
                <p>+1 Email Address (Optional)</p>
                <input name="companionEmailAddress"
                    className="smallInput"
                    placeholder="+1 Email Address"
                    value={companionEmail}
                    onChange={(val) => { setCompanionEmail(val.target.value); setStatusMsg("") }} />
                <p>+1 Participation Choice</p>
                <input 
                    type="radio"
                    id="companion_theme_military"
                    value="Galactic Alliance Military"
                    checked={ 
                        companionThemePreference === 
                        "Galactic Alliance Military"
                    } 
                    onChange={() => {
                        setCompanionThemePreference( 
                            "Galactic Alliance Military"
                        ) 
                        setStatusMsg("");
                        setStatusPrimary("");
                        setStatusPlusOne("");
                        }
                    } 
                /> 
                <label>Galactic Alliance Military</label> 
                <br></br>
                <br></br>
                <input 
                    type="radio"
                    id="companion_theme_civilian"
                    value="Extraordinary Citizen"
                    checked={ 
                        companionThemePreference === 
                        "Extraordinary Citizen"
                    } 
                    onChange={() => {
                        setCompanionThemePreference( 
                            "Extraordinary Citizen"
                        ) 
                        setStatusMsg("");
                        setStatusPrimary("");
                        setStatusPlusOne("");
                        }
                    } 
                /> 
                <label>Extraordinary Citizen</label>
                <p>+1 Badge Name</p>
                <input name="companion_customName"
                className="smallInput"
                placeholder="+1 Badge Name"
                value={companionName}
                onChange={(val) => { 
                    setCompanionName(val.target.value); 
                    setStatusMsg("");
                    setStatusPrimary("");
                    setStatusPlusOne("");}} />
                <p>+1 Badge Pronouns (Optional)</p>
                <input name="companion_badgePronouns"
                className="smallInput"
                placeholder="+1 Badge Pronouns"
                value={companionPronouns}
                onChange={(val) => { 
                    setCompanionPronouns(val.target.value); 
                    setStatusMsg("");
                    setStatusPrimary("");
                    setStatusPlusOne("");}} />
                <p>Discord User ID</p>
                <input 
                className="smallInput"
                id="discord"
                value={discordPlusOneName}
                placeholder="Discord User ID"
                onChange={(val) => {
                    setDiscordPlusOneName(val.target.value); 
                    setCustomName(""); 
                    setStatusMsg("");
                    setStatusPrimary("");
                    setStatusPlusOne("");
                    }
                } 
                /> 
            </div>} 
            <hr/>
            <p>{statusPrimary}</p>
            <p>{statusPlusOne}</p>
            <p>{display_msg()}</p>
            {display_msg() === "" && <button
                onClick={() => submit_validation()}
            >Validate Registration</button>}
        </div>
    )
};

export default Validation;