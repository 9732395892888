import React from 'react';

const eventKidsGameClub = (props: any) =>
(
  <aside className="eventOverview">
    <header className="eventOverview-header">
      Kids Game Club
    </header>
    <main className="eventOverview-body">
      <p>This Quarterly we are piloting the “Kids Game Club” for children ages 5 - 12. This is a drop-off program where kids participating in the program will play board games together under the supervision of an early childhood education professional from 1PM to 6PM.</p>
      <p>Registration is $10 per child and includes admission to all 14 hours of the Quarterly on top participation in the Kids Game Club program. For this pilot, we only have 6 registrations available so register your children right away before all of the spots are gone! Parents may register children for this program through <a href="https://tabletopga.square.site/" target="_blank" rel="noreferrer">our storefront on Square.</a> Should any Kids Game Club registrations remain on the morning of the Quarterly, they may be purchased for children at the door.</p>
      <p>Note: registration for the Kids Game Club does not include any parents' registration for the Quarterly. Parents must purchase separate registrations for themselves!</p>
      <p>Parents are expected to pick their children up either promptly at 6PM or any time before then.</p>
      <p>Below is an introduction to our early childhood education professional, Jessica.</p>
      <img src="https://i.tabletopgamersalliance.com/q/q3/2019/jess_bio.jpg" alt="" className="kids_item"></img>
      <p>Hello! My Name is Jessica Bianco and I am a graduate of Westfield State University with my Bachelors of Science in Early Childhood Education. I have been around and love kids of all ages! I started teaching swimming lessons to 3 & 4 year olds back in 2014, took a break to get my B.S., and then continued my career with children by working in afterschool programs within the various Framingham Public Schools. From there, I transferred over to Natick Public Schools for a change of scenery and more challenges. I accepted a job last year as a paraprofessional in Dedham at the Early Childhood Center where my primary role is assisting children having a hard time accessing the curriculum due to poor social/emotional behaviors in the classroom. Additionally, I babysat during the summer for a family with 3 children (ages 5,7,9 now) taking them to the local pond and making sure they were safe and happy during the summer. My motto is, "Let kids have fun while keeping them safe”!</p>
    </main>
    <footer className="landing-footer">
    </footer>
  </aside>
);

export default eventKidsGameClub;
