import { LatLngTuple } from "leaflet"
import {useEffect, useState} from 'react'
export { getAllVenues, getVenueDetails, createVenue, updateVenue } from "../venueModels"


export async function isValidAddress(entry:string): Promise<boolean> {
  const addresses = await fetch(`${process.env.REACT_APP_MAPAPI}/search.php?q=${encodeURIComponent(entry)}&format=jsonv2`,
    {mode: 'cors', headers: {'Access-Control-Allow-Origin':'https://tabletopgamesalliance.com'}}
    ).then(res=>res.json())

  return addresses.length > 0
}

// TODO: Insert proper return type
export async function getAddressData(entry:string): Promise<any[]> {
  const addresses = await fetch(`${process.env.REACT_APP_MAPAPI}/search.php?q=${encodeURIComponent(entry)}&format=jsonv2`,
    {mode: 'cors', headers: {'Access-Control-Allow-Origin':'https://tabletopgamesalliance.com'}}
    ).then(res=>res.json())
  return addresses
}

export async function getAddressLatLon(entry:string): Promise<LatLngTuple> {
  const addresses = await fetch(`${process.env.REACT_APP_MAPAPI}/search.php?q=${encodeURIComponent(entry)}&format=jsonv2`,
    {mode: 'cors', headers: {'Access-Control-Allow-Origin':'https://tabletopgamesalliance.com'}}
    ).then(res=>res.json())
  if(addresses.length > 1) console.warn('getAddressLatLon called with ambiguous address')
  if(addresses.length === 0) throw(new Error('getAddressLatLon called with invalid address')) //TODO: Handle this error 
  
  const a = addresses[0]
  return [parseFloat(a.lat), parseFloat(a.lon)] as LatLngTuple
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    windowWidth:width,
    windowHeight:height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const debounce = (cb:Function, d:number) => {
  let timer:any

  return function (...args:any[]) {
    if(timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(()=> {
      cb(...args);
    },d);
  };
};

export function submitVenue() {

}