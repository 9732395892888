export interface DB_Error {
    result: string,
    reason: string,
    exception?: string,
    message?: string
}

export interface GETAllVenues {
    data: GETAllVenues_Single[],
    result: string
}

export interface GETAllVenues_Single {
    id: number,
    name: string
}

export interface VenueDropdownListItem {
    value: number,
    label: string
}

export interface GETVenueDetails {
    data: VenueDetails,
    result: string
}

export interface VenueDetails {
    country: string,
    detailed_directions?: string,
    id: number,
    introduction: string,
    library: number,
    name: string,
    owner: number,
    postcode: string,
    private: boolean,
    street_address?: string,
    agg_address?:string
}

function isGetAllVenues(resp: GETAllVenues | DB_Error): resp is GETAllVenues {
    return (resp as GETAllVenues).result === 'success';
}

function isGetVenueDetails(resp: GETVenueDetails | DB_Error): resp is GETVenueDetails {
    return (resp as GETVenueDetails).result === 'success';
}

export interface CreateVenueInput {
    name: string,
    country: string,
    detailed_directions?: string,
    introduction?: string,
    uid: number,
    postcode: string,
    private: boolean,
    street_address: string,
    agg_address:string
}

export const getAllVenues = async (): Promise<GETAllVenues_Single[] | undefined> => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
    }
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/venue/all', requestOptions)
        .then((response) => {
            if (!response.ok) {
                console.log('Fetch Error in getAllVenues')
                console.log(`HTTP Response Code: ${response.status}`)
                throw new Error('Bad Fetch');
            } else {
                return response;
            }
        })
        .then(res => res.json())
        .then((res: DB_Error | GETAllVenues) => {
            if (isGetAllVenues(res)) {
                return res.data;
            } else {
                console.log(`Backend Error in getAllVenues Reason ${res.reason}`)
                res.exception && console.log(`Exception ${res.exception}`)
                res.message && console.log(`Message ${res.message}`)
                return undefined;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in getAllVenues')
            console.log(`Reason: ${reason}`)
            return undefined;
        })
}

export const getVenueDetails = async (id: number, authToken?: string, user?: number): Promise<VenueDetails | undefined> => {

    const requestOptions = {
        method: 'GET',
        headers: authToken ? { 'Authorization': `Bearer ${authToken}` } : { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
    }
    //TODO: Attempt private first, then if failed try Public. Rather than the other way around.
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/venue/' + id + (user ? '?uid=' + user : ''), requestOptions)
        .then(res => res.json())
        .then((res: GETVenueDetails | DB_Error) => {
            if (isGetVenueDetails(res)) {
                return res.data;

            } else {
                console.log(`Backend Error in getVenueDetails Public Reason ${res.reason}`)
                res.exception && console.log(`Exception ${res.exception}`)
                res.message && console.log(`Message ${res.message}`)
                return undefined;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in getVenueDetails Public')
            console.log(`Reason: ${reason}`)
            return undefined;
        })
}

export const createVenue = (input: CreateVenueInput, authToken: string): Promise<boolean> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${authToken}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(input)
    }
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/venue/create', requestOptions)
        .then(res => res.json())
        .then((res) => {
            if (res.status && res.status === `success`) {
                return true;
            } else {
                console.log(`Backend Error in CreateVenue`);
                res.reason && console.log(`Reason: ${res.reason}`);
                return false;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in CreateVenue')
            console.log(`Reason: ${reason}`)
            return false;
        })
}

export const updateVenue = (input: VenueDetails, authToken: string): Promise<boolean> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${authToken}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(input)
    }
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/venue/' + input.id + (input.owner ? '?uid=' + input.owner : ''), requestOptions)
        .then(res => res.json())
        .then((res) => {
            if (res.status && res.status === `success`) {
                return true;
            } else {
                console.log(`Backend Error in UpdateVenue`);
                res.reason && console.log(`Reason: ${res.reason}`);
                res.exception && console.log(`Exception ${res.exception}`)
                res.message && console.log(`Message ${res.message}`)
                return false;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in UpdateVenue')
            console.log(`Reason: ${reason}`)
            return false;
        })
}