import React from 'react';

const registration = (props: any) =>
(
  <aside className="registration">
    <header className="registration-header">
    </header>
    <main className="registration-body">
      <div className="registration-left">
        <span id="registration_container" className="registration">
		      <br /><br /><br />
		        <span id="registration_sub_container" className="registration">
				        <p>
						          <a href="https://checkout.square.site/merchant/BK0PVAJPXWGEA/checkout/QE2YUOM2O423CUP6OEJYQNYP" target="_blank"  rel="noreferrer">
								              <img src="https://i.tabletopgamersalliance.com/p/2019/q1/2019_Q1_online_registration_avatar.png" alt="" className="registration_item" />
                      </a>
                </p>

                <a className="button red" href="https://checkout.square.site/merchant/BK0PVAJPXWGEA/checkout/QE2YUOM2O423CUP6OEJYQNYP" target="_blank"  rel="noreferrer">
                      Purchase on &nbsp;
						          <img src="https://i.tabletopgamersalliance.com/p/2019/q1/square_logo_button.png" alt="" className="registration_button" />
                </a>
            </span>
        </span>

        <h2>Registration</h2>
          <p>
		        Register for the 2019 Q1 Quarterly via our store on <img src="https://i.tabletopgamersalliance.com/p/2019/q1/square_logo.png" alt="" />
          </p>
          <p>
		        Online registration for entry to the Tabletop Gamers Alliance 2019 Q1 All-Day Quarterly on March 23rd, 2019. After check-out, your name and/or e-mail address will be added to the registration list. When checking in at the event at the door, just mention that you registered online..
          </p>
          <p>
		        If purchasing multiple registrations, list all the names you are purchasing
		        registrations for during check-out in the "Write a note to the seller" field.
          </p>
          <p>
		        Online registrations for this event will be available at two different price tiers:
          </p>
	          <ul>
				{/*<li style="text-decoration: line-through;">*/}
				      <li>
					        Early-Bird: $10 / person
			            <ul>
						          <li>LIMITED quantity available</li>
				              <li>Add the Early-Bird registration to the shopping basket at our Square online store to register at this special rate.</li>
	                </ul>
              </li>
		          <br />
		          <li>
					        Online (regular): $15 / person
			            <ul><li>available until 1PM on March 22nd</li></ul>
              </li>
            </ul>
          <p>
		        Registration at the door will be available at a rate of $20 / person.
          </p>
      </div>
    </main>
    <footer className="landing-footer">
    </footer>
  </aside>

);

export default registration;
