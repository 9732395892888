import React from 'react';
import TitanBusterCalendar from './EventCalendar';
import moment from 'moment-timezone';
import { CalEvent } from './EventCalendar';
import { useAuth0 } from "@auth0/auth0-react";
import { EventInfo } from '../../../../models/event/eventModel';

export type Value = any;

interface EventStruct {
  bgg_title_link: string,
  end_time: string,
  start_time: string,
  event: number,
  id: number,
  info: string,
  name: string,
  owner: number,
  room: string,
  slots: number,
  table: number,
  user_name: string,
}

interface gSessionQuery {
  data: EventStruct[]
}

interface participantQuery {
  user_name: string
}

interface userQuery {
  result: string
}

interface eventID {
  event: number
  name: string
}

interface eventQuery {
  data: eventID[]
}

interface allEventID {
  startTime: Value,
  endTime: Value,
  end_time: Value,
  highestTableNumber: number | null,
  id: number,
  info: string,
  lowestTableNumber: number | null,
  name: string,
  owner: string,
  slots: number,
  start_time: Value,
  venue: number,
  website_url?: string
}

interface allEventQuery {
  data: allEventID[]
}

interface roleID {
  role: number
  name: string
}

interface permsQuery {
  data: roleID[]
}

interface regMutation {
  event: number,
  user: number
}

interface ScheduleProps {
    eventName: string,
    creatorRole?: string
}

function Calendar(props: ScheduleProps) {

  const { user, getAccessTokenSilently } = useAuth0();

  const [scope, setScope] = React.useState<number>(0);
  const [evtList, setEvtList] = React.useState<CalEvent[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [userStatus, setUserStatus] = React.useState<number>(0);
  const [userToken, setUserToken] = React.useState<string>("");
  //const [evtId, setEvtId] = React.useState<number>(0);
  const [event, setEvent] = React.useState<EventInfo>();

  const [userTGAid, setUserTGAID] = React.useState<number>(0);
  const [userTGAName, setUserTGAName] = React.useState("");

  interface IMeta {
    tga_id: any
  }

  const getTGAid = React.useCallback((dat: IMeta) => {
    return (dat.tga_id);
  }, []);

  React.useEffect(() => {

    const getUserData = async (userAuth0Name: string) => {
      if (userAuth0Name !== "") {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
              scope: "read:current_user",
            },
          });

          const userDataResponse = await fetch(process.env.REACT_APP_REFLEBULA + "/a1/tga/user/" + userAuth0Name + "", { headers: { Authorization: `Bearer ${accessToken}`, }, });

          const { user_name } = await userDataResponse.json();

          setUserTGAName(user_name);
        }
        catch (e: any) {
          console.log("app=>getUserData: " + e.message);
        }
      }
    };

    const getUserCredentials = async () => {
      if (user!) {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
            scope: "read:current_user",
          },
        });
        setUserToken(accessToken);

        let userDetailsByIdUrl = "";


        userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user.sub}`;


        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const { user_metadata } = await metadataResponse.json();

        console.log(user_metadata);
        var temp_id = getTGAid(user_metadata);

        //console.log("tga_id = " + temp_id);
        setUserTGAID(temp_id);
        getUserData(temp_id);
        //console.log("tga_id state = " + userTGAid);
      } else {
        setUserTGAID(0);
      }
    }
    getUserCredentials();
  }, [getAccessTokenSilently, getTGAid, user])

  const simulated_user = userTGAid;

  const simulated_token = `Bearer ${userToken}`;



  React.useEffect(() => {

    const getUserStatus = async (user: number): Promise<number> => {
      setScope(0);
      const requestOptions = {
        method: 'GET',
        headers: { "Access-Control-Allow-Origin": "*", 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
      }
      // Check if user exists
      const userQuery: userQuery = await fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/user/' + user, requestOptions)
        .then(response => response.json())
      if (userQuery.result === 'failure') { setUserStatus(0); return 0; }
      // Check if user is attendinging event
      const eventQuery: eventQuery = await fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/eventUsers/user/' + user, requestOptions)
        .then(response => response.json())
      console.log(eventQuery.data);
      if (eventQuery.data.filter((evt) => evt.name === props.eventName).length === 0) { setUserStatus(1); return 1; }
      // Check if user has event editing permissions
      // TODO: Add name to role search
      const permsQuery: permsQuery = await fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/userRoles/user/' + user, requestOptions)
        .then(response => response.json())
      if (permsQuery.data.filter((role) => (role.name === "Admin" || role.name === props?.creatorRole)).length === 0) { setUserStatus(2); return 2; }
      setUserStatus(3);
      return 3;
    }

    simulated_user && getUserStatus(simulated_user);
  }, [simulated_user])


  React.useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
    }

    const fetchData = async (url: string) => {
      const data: gSessionQuery = await fetch(url, requestOptions).then(res => res.json())
      console.log("fetching schedule...")
      const partresp = await Promise.all(
        data.data.map(async (evt: EventStruct) => {
          return await fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/gsessions/viewgsessions/participants/' + evt.id, requestOptions).then(response => response.json())
        }))
      console.log(partresp);
      const calView = data.data.map(((evt, index) => {
        console.log(evt);
        const part = partresp[index]
        const slotsused = part.count
        const particpants = part.data.map((val: participantQuery) => {
          return val.user_name
        })
        const startGMT = moment.tz(evt.start_time, "ddd DD MMM YYYY HH:mm:ss GMT", "GMT");
        const endGMT = moment.tz(evt.end_time, "ddd DD MMM YYYY HH:mm:ss GMT", "GMT");
        const newval: CalEvent = {
          bgg_title_link: evt.bgg_title_link,
          slots_available: evt.slots - slotsused,
          owner: evt.user_name,
          info: evt.info,
          room: evt.room,
          table: evt.table,
          id: evt.id,
          title: evt.name,
          start: startGMT.clone().tz("America/Los_Angeles").toDate(),
          end: endGMT.clone().tz("America/Los_Angeles").toDate(),
          participants: particpants
        }
        return newval;
      }))
      console.log(calView);
      setEvtList(calView);
      setLoading(false);
    }

    const fetchAllEvents = async () => {
      const eventQuery: allEventQuery = await fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/events/all', requestOptions)
      .then(response => response.json())
      console.log(eventQuery.data);
      const event= eventQuery.data.filter((evt) => evt.name === props.eventName)[0];
      setEvent(event);
      fetchData(process.env.REACT_APP_REFLEBULA + '/a1/tga/gsessions/viewgsessions/' + event.id)
    }
    setLoading(true);

    fetchAllEvents();
  }, [])

  const revpRequest = ((event: CalEvent): Promise<boolean> => {
    const user_to_add = simulated_user
    console.log(event);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': `application/json`,
        "Access-Control-Allow-Origin": "*",
        'Authorization': simulated_token
      }
    }
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/gsessions/rsvp/' + event.id + '/user/' + user_to_add, requestOptions)
      .then(res => res.json())
      .then((result) => {
        if (result.status === 'success') {
          return true
        }
        else {
          console.log(result)
          return false
        }
      })
  })

  const eventRegister = async (): Promise<boolean> => {
    if (simulated_user == null) {
      return Promise.resolve(false);
    }
    const user_to_add = simulated_user

    const requestOptionsGet = {
      method: 'GET',
      headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
    }
    const evt_id = event?.id ? event.id : 0;

    const reqbody: regMutation = {
      user: user_to_add,
      event: evt_id
    }
    const requestOptions = {
      body: JSON.stringify(reqbody),
      method: 'POST',
      headers: {
        'Content-Type': `application/json`,
        "Access-Control-Allow-Origin": "*",
        'Authorization': simulated_token
      }
    }
    console.log(requestOptions);
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/eventUsers/addmap', requestOptions)
      .then(res => res.json())
      .then((result) => {
        if (result.status === 'success') {
          setUserStatus(2);
          return true
        }
        else {
          console.log(result)
          return false
        }
      })
  }

  return (
      <div>
        {!loading && event && <TitanBusterCalendar
          eventList={evtList}
          onRsvp={revpRequest}
          userStatus={userStatus}
          onEventUp={eventRegister}
          userId={simulated_user}
          userName={userTGAName}
          token={simulated_token}
          event={event}
        />}
        <img src="https://titanbuster.com/i/TitanBuster/2024/TitanBuster_2024_numbered_layout.png" />
      </div>
  );
}

export default Calendar;
