export interface DB_Error {
    result: string,
    reason: string,
    exception?: string,
    message?: string
}

export interface titanBusterValidate {
    result: string,
    primary: any,
    plusOne: any
}

export interface ValidatePlusOne {
    email_address ?: string,
    theme_choice: string,
    custom_name: string,
    badge_pronouns: string,
    discord_id ?: string
}

export interface titanBusterValidateInput {
    user: number,
    receiptId: string,
    email: string,
    theme_choice: string,
    badge_name_type: string,
    custom_name ?: string,
    badge_pronouns: string,
    plus_one ?: ValidatePlusOne,
    discord_id ?: string
}

export function isTitanBusterValidate(resp: titanBusterValidate | DB_Error | undefined): resp is titanBusterValidate {
    return (resp as titanBusterValidate).result === 'success' ? true : false;
}

export const validateTitanBusterRegistration = async (input : titanBusterValidateInput): Promise<titanBusterValidate | DB_Error | undefined> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==', 'Content-Type': 'application/json' },
        body: JSON.stringify(input)
    }
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/TitanBuster/registration', requestOptions)
        .then(res => res.json())
        .then((res: DB_Error | titanBusterValidate) => {
            if (isTitanBusterValidate(res)) {
                return res;
            } else {
                console.log(`Backend Error in getAllEvents Reason ${res.reason}`)
                res.exception && console.log(`Exception ${res.exception}`)
                res.message && console.log(`Message ${res.message}`)
                return res;
            }
        })
}