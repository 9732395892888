import React from 'react';

const eventOverview = (props: any) =>
(
  <aside className="eventOverview">
    <header className="eventOverview-header">
    </header>
    <main className="eventOverview-body">
      <p>
        We're calling this event Gamacea, because its the con that is the cure for all the boredom that ails you! Gamacea will begin at 10AM on Saturday, June 15th,
        at the Hampton Inn in Natick nearby the Natick/Route 30 exit off the Pike and close to the Natick Collection (Mall).
        Anyone of game-playing age who is interested in playing board games is welcome.
      </p>

      <p>
        Gamacea will take place in the Main Ballroom (AKA Middlesex + Plymouth), Essex, and Hampton function rooms at the hotel.
        Using the following layout we have 100 seats in the Main Ballroom, 16 seats in the Essex, and 12 seats in the Hampton for a grand total of 128 seats across 25 tables.
      </p>

      <p>
        Gamacea will close at 6PM on Sunday, June 16th.
      </p>


      <b>What is Gamacea?</b>

      <p>
        Gamacea is a two-day board gaming convention with 32 hours straight of open gaming and scheduled gaming.
        There will be board games for all types of gamers: casual, Euro, theme, heavy, war, and social.
      </p>

      <p>
        With a span of 32 hours, it is feasible to play those games that take 3+ hours that just never seem to happen at your weeknight game nights.
      </p>


      <b>What is "Open Gaming"?</b>

      <p>
        In a nutshell, ad hoc gaming or simply playing whatever games you would like.
        In practice, it is a large group of gamers gathered to play games and then splitting up into many smaller pick-up games.
        Feel free to bring and share your own games or not. If not, you can simply arrive and play other people's games.
      </p>


      <b>How do I join a game if I don't know anyone else at Gamacea?</b>

      <p>
        Please refer to our <button className="buttonlink" onClick={() => props.navcallback("eventPlayerMatching")}>Player Matching</button> section.
      </p>


      <b>Do I have to be a member of TGA to attend?</b>

      <p>
        You do not! The event is open to the general public!
      </p>

      <p>
        However, becoming a member of TGA is free! You can <a href="https://www.meetup.com/TabletopGamersAlliance/?action=join" target="_blank" rel="noreferrer">join TGA on Meetup.com here.</a>
      </p>


      <b>Do I have to RSVP to attend?</b>

      <p>
        RSVP'ing is not required.
        However, it is very much appreciated if you do RSVP, because it helps show everyone the overall level of interest in the event
        and helps us better determine if the facilities we have rented are adequate for the number of people expected to attend.
      </p>

      <p>
        You must become a member of TGA on meetup in order to RSVP. Becoming a member of TGA is free! You can <a href="https://www.meetup.com/TabletopGamersAlliance/?action=join" target="_blank" rel="noreferrer">join TGA on Meetup.com here.</a>
      </p>


      <b>Is there a difference between RSVP'ing and registering?</b>

      <p>
        Yes. RSVP'ing is simply just announcing your intent to attend. Registering is paying the entry fee to attend the event.
        You can get more details about registering on our <button className="buttonlink" onClick={() => props.navcallback("registration")}>Registration</button> page.
      </p>
    </main>
    <footer className="landing-footer">
    </footer>
  </aside>
);

export default eventOverview;
