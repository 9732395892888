import moment from 'moment'
import CollapsableContent from './CollapsableContent'
import { UserDetails } from '../../models/user/userInfo'
import './CollapsableVenue.css'
import React from 'react';
import SessionTable from '../custom/session/SessionTable'
import { EventInfo } from "../../models/event/eventModel"
import { SessionSignUpStatus } from '../../functions/Sessions';

export interface CalSessionC
{
    bgg_title_link?: string,
    owner: string,
    id: number,
    name: string,
    title: string,
    start: Date,
    end: Date,
    info: string,
    room: string,
    table: number,
    slots_available: number,
    participants: string[],
    count: number
}

interface CollapsibleScheduleProps
{
    evnt: EventInfo,
    sessionCList: CalSessionC[],
    startingIsCollapsed: boolean,
    user?: UserDetails,
    onSignUpChange: (sesh: number, signUpStatus: boolean) => Promise<boolean>
    registrantList : UserDetails[],
    onFullSchedule: () => void,
    authToken?: string
}

const CollapsibleSchedule = (props: CollapsibleScheduleProps) =>
{

    const [enableSignUp, setEnableSignUp] = React.useState<boolean>(false);
    const [headerMessage, setHeaderMessage] = React.useState<String|undefined>();
    const [sortedSeshList, setSortedSeshList] = React.useState<CalSessionC[]>([]);

    React.useEffect(() => {

        let sortedSessionCList = props.sessionCList;

        sortedSessionCList.sort((a,b) => {
            const aTime = a.start.getTime()
            const bTime = b.start.getTime()
            if (aTime!== bTime) {
                return aTime > bTime ? 1: -1;
            } else {
                return a.end.getTime() > b.end.getTime() ? 1: -1;
            }
        })

        const mappedSortedSessionCList = sortedSessionCList.map((val,indx) => {
            return {...val, count:indx};
        });

        setSortedSeshList(mappedSortedSessionCList);

    },[props.sessionCList])

    React.useEffect(() => {
        if(!props.user) {
            setEnableSignUp(false);
            setHeaderMessage(`You are not logged in! Log into TGA to RSVP to this event!`)
        }
        else if(props.user && props.registrantList.filter((usr) => {return usr.id === props.user?.id}).length === 0) {
            console.log('DISABLED');
            setEnableSignUp(false);
            setHeaderMessage(`You need to RSVP to this event in order to sign up for and schedule game sessions!`)
        }
        else {
            console.log('ENABLED');
            setEnableSignUp(true);
            setHeaderMessage(undefined);
        }
    }, [props.user, props.registrantList])



    const openFullScheduleClick = () => {
        props.onFullSchedule()
    }

    const onSignUpChange = (sesh: number, signUpStatus: boolean) => {
        //return toggleUserSessionStatus({user: props.user?.id, authToken: props.authToken}, sesh ,signUpStatus);
        return props.onSignUpChange(sesh,signUpStatus);
    }

    const isSeshSignUpState = (sesh: CalSessionC, enable: boolean, user?: UserDetails): SessionSignUpStatus => 
    {
        // If there's no user, we know we can't perform any Signup/relenquish Operations
        // If a session has started, it's too late to Signup or relenquish a slot
        if(!user || sesh.start < new Date()) {
            return SessionSignUpStatus.notPermitted;
        }
        // Otherwise, a user should be able to relinquish their slot if they have already signed up
        else if(sesh.participants.includes(user.user_name)){
            console.log(`SIGNED UP`);
            return SessionSignUpStatus.signedUp;
        }
        // But they should only be able to sign up if they have RSVPed (see enableSignUp) and slots are available
        else if(!enable || sesh.slots_available == 0) {
            return SessionSignUpStatus.notPermitted;
        }
        else {
            console.log(`NOT SIGNED UP`);
            return SessionSignUpStatus.notSignedUp;
        }
    }


    if(sortedSeshList.length > 0)
    {
        let headings = ["Time", "Table", "Session", "Open Slots", ""];
        
        return(
            <div>
                <CollapsableContent title={"Schedule"} startingIsCollapsed={props.startingIsCollapsed}>
                    <div>
                        <b>{headerMessage}</b>
                        <br></br>
                        <SessionTable onSignUpChange={onSignUpChange} seshSignUpStates={sortedSeshList.map((sesh) => isSeshSignUpState(sesh, enableSignUp, props.user))} enableSignUp={enableSignUp} user={{user: props.user?.id, authToken: props.authToken}} headings={headings} body={sortedSeshList.map((seshC: CalSessionC) => [moment(seshC.start).format('h:mmA') + " - " +  moment(seshC.end).format('h:mmA'), seshC.table.toString(), seshC.name, seshC.slots_available.toString(), seshC.count.toString()])} seshIds={sortedSeshList.map((sesh) => sesh.id)}></SessionTable>
                        <p></p>
                        <button onClick={openFullScheduleClick}>Session Schedule</button>
                    </div>
                </CollapsableContent>
            </div>
        )
    }
    else
    {
        return(
            <div>
                <CollapsableContent title={"Schedule"} startingIsCollapsed={props.startingIsCollapsed}>
                    <div>
                        <b>{headerMessage}</b>
                        <br></br>
                        <p>View the Session Scheduler to schedule a session for this event.</p>
                        <button onClick={openFullScheduleClick}>Session Scheduler</button>
                    </div>
                </CollapsableContent>
            </div>
        )
    }

}

export default CollapsibleSchedule;