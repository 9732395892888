import React from 'react'
import { NavigateAction, momentLocalizer } from 'react-big-calendar'
// @ts-ignore
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import moment from 'moment'

const localizer = momentLocalizer(moment)

interface MyWeekProps {
    date: Date,
    calendarName: string
}

export class CustomWeekView extends React.Component<MyWeekProps> {
    static title = (date: Date) => {
        return `Titan Buster Schedule`
    }

    static navigate = (date: Date, action: NavigateAction) => {
        switch (action) {
            case 'PREV':
                return moment(date).add(-3, 'day').toDate()

            case 'NEXT':
                return moment(date).add(3, 'day').toDate()

            default:
                return date
        }
    }

    static range = (date: Date) => {
        let start = date
        let end = moment(start).add(2, 'day')

        let current = start
        let range = []

        while (moment(current).isSameOrBefore(moment(end), 'day')) {
            range.push(current)
            current = moment(current).add(1, 'day').toDate()
        }

        return range
    }

    readonly max = localizer.endOf(new Date(), 'day');
    readonly min = localizer.startOf(new Date(), 'day');

    render() {
        let { date } = this.props
        let range = CustomWeekView.range(date)

        return <TimeGrid {...this.props} min={this.min} max={this.max} range={range} eventOffset={15} step={30} />
    }
}